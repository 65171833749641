import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Circle } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import mapIconImage from "../../Images/mapIcon.png";

const GeofencingMap = () => {
  const [center, setCenter] = useState([51.505, -0.09]); // Default fallback location
  const [radius, setRadius] = useState(1000); // Default radius in meters
  const [isLocationSet, setIsLocationSet] = useState(false); // Track if location is set

  // Custom icon
  const mapIcon = new L.Icon({
    iconUrl: mapIconImage,
    iconSize: [45, 45], // Adjust icon size if necessary
    iconAnchor: [22.5, 45], // Adjust anchor if necessary
    popupAnchor: [0, -45], // Adjust popup anchor if necessary
    shadowUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png",
    shadowSize: [41, 41],
  });

  // Get user's current location
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCenter([latitude, longitude]);
          setIsLocationSet(true);
        },
        (error) => {
          console.error("Error fetching location:", error);
          setIsLocationSet(true); // Allow map to render even if location fetch fails
        }
      );
    } else {
      console.warn("Geolocation is not supported by this browser.");
      setIsLocationSet(true);
    }
  }, []);

  const handleRadiusChange = (e) => {
    setRadius(Number(e.target.value)); // Update radius
  };

  const handleMarkerDragEnd = (e) => {
    const newLatLng = e.target.getLatLng();
    setCenter([newLatLng.lat, newLatLng.lng]); // Update center when marker is dragged
  };

  return (
    <div>
      <h2>Geofencing Configuration</h2>
      <div style={{ marginBottom: "10px" }}>
        <label>
          Radius (meters):{" "}
          <input
            type="number"
            value={radius}
            onChange={handleRadiusChange}
            min="100"
          />
        </label>
      </div>
      {isLocationSet ? (
        <MapContainer
          center={center}
          zoom={13}
          style={{ height: "500px", width: "100%" }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker
            position={center}
            draggable // Make the marker draggable
            eventHandlers={{
              dragend: handleMarkerDragEnd, // Update the center when dragging ends
            }}
            icon={mapIcon} // Set the custom icon
          />
          <Circle center={center} radius={radius} />
        </MapContainer>
      ) : (
        <p>Loading map...</p>
      )}
    </div>
  );
};

export default GeofencingMap;
