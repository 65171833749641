import "./table.scss";
import { useEffect, useState, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { ref, query, orderByChild, onValue } from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext";

const List = () => {
  const [rows, setRows] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const userCompanyId = currentUser.companyId;
  const userRole = currentUser.role; // Get the current user's role

  useEffect(() => {
    const watchesRef = ref(database, "Watches");
    const q = query(watchesRef, orderByChild("companyId"));

    const unsub = onValue(q, async (snapshot) => {
      let watchesList = [];
      const watchPromises = []; // To hold promises for fetching the isOnline status

      snapshot.forEach((childSnapshot) => {
        const watchData = { id: childSnapshot.key, ...childSnapshot.val() };

        // Only include watches that have a displayName, watchId, and apply the role-based filter
        if (
          watchData.displayName &&
          watchData.displayName.trim() !== "" &&
          watchData.watchId && 
          watchData.watchId.trim() !== "" && // Check for valid watchId
          (userRole === 1 || (userRole === 2 || userRole === 3) && watchData.companyId === userCompanyId)
        ) {
          watchesList.push(watchData);

          // Fetch the isOnline status for each watch
          const watchRef = ref(database, `Watches/${watchData.id}/isOnline`);
          watchPromises.push(
            new Promise((resolve) => {
              onValue(watchRef, (watchSnapshot) => {
                const isOnline = watchSnapshot.val();
                resolve({ id: watchData.id, isOnline });
              });
            })
          );
        }
      });

      // Wait for all watch status data to be fetched
      const watchStatuses = await Promise.all(watchPromises);

      // Map statuses to the watches list
      watchesList = watchesList.map((watch) => {
        const watchStatus = watchStatuses.find((status) => status.id === watch.id);
        return {
          ...watch,
          isOnline: watchStatus ? watchStatus.isOnline : null, // Default to null if not found
        };
      });

      // Sort the list to show online watches first
      watchesList.sort((a, b) => (b.isOnline ? 1 : 0) - (a.isOnline ? 1 : 0));

      setRows(watchesList); // Update the state with the sorted list
    });

    // Cleanup subscription on component unmount
    return () => {
      unsub();
    };
  }, [userCompanyId, userRole]);

  // Map role to description (optional)
  const getRoleDescription = (role) => {
    switch (role) {
      case 1:
        return "Super Admin";
      case 2:
        return "Company Administrator";
      case 3:
        return "Standard User";
      case 4:
        return "Watch User";
      default:
        return "Unknown";
    }
  };

  // Define columns for DataGrid
  const columns = [
    { field: "displayName", headerName: "Watch User", flex: 1 },
    { field: "watchId", headerName: "Watch Id", flex: 1 },
    {
      field: "isOnline",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value ? "green" : "red",
            fontWeight: "500",
          }}
        >
          {params.value ? "Online" : "Offline"}
        </span>
      ),
    },
    { field: "watchDisplayName", headerName: "Display Name", flex: 1 },
    {
      field: currentUser.role === 1 ? "companyName" : "locationName",
      headerName: currentUser.role === 1 ? "Company Name" : "Location Name",
      flex: 1,
    },
    { 
      field: "phoneNumber", 
      headerName: "Contact Number", 
      flex: 1,
      renderCell: (params) => (
        <span>{params.value ? params.value : "N/A"}</span>
      ),
    }
  ];

  return (
    <div className="datatable">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        getRowId={(row) => row.id}
      />
    </div>
  );
};

export default List;
