import "./singleWatch.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useEffect, useState, useContext } from "react";
import {
  ref,
  get,
  update,
  onValue,
  push,
  set,
  remove,
  query,
  orderByChild,
  limitToLast,
} from "firebase/database";
import { database } from "../../firebase";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
  Circle,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { AuthContext } from "../../context/AuthContext";
import mapIconUrl from "../../Images/mapIcon.png";
import warningIconUrl from "../../Images/warningIcon.png";
import L from "leaflet";
import SingleWatchLogDataTable from "../../components/datatable/SingleWatchLogDataTable";
import periodIcon from "../../Images/period.png";
import redPeriodIcon from "../../Images/redperiod.png";
import orangePeriodIcon from "../../Images/orangeperiod.png";
import greenPeriodIcon from "../../Images/greenperiod.png";
import redWatchIconUrl from "../../Images/1.png";
import orangeWatchIconUrl from "../../Images/2.png";
import greenIconUrl from "../../Images/3.png";
import cancelledIconUrl from "../../Images/4.png";
import { useNavigate } from "react-router-dom"; // Import useNavigate for redirection
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import {
  FormControl,
  TextField,
  Switch,
  Button,
  Modal,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SubscribeToPlanModal from "../Modals/SubscribeToPlanModal";
import zIndex from "@mui/material/styles/zIndex";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween); // Enable the isBetween function

const SingleWatch = () => {
  const [watchDetails, setWatchDetails] = useState(null);
  const [watchId, setWatchId] = useState(null);
  const [formValues, setFormValues] = useState({
    displayName: "",
    locationName: "",
    companyName: "",
    groupCode: "",
    locationId: "",
    companyId: "",
    userId: "",
    phoneNumber: "",
    watchDisplayName: "",
  });
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState("");
  const [locations, setLocations] = useState([]); // New state for locations
  const [filteredLocations, setFilteredLocations] = useState([]); // New state for filtered locations
  const { currentUser } = useContext(AuthContext);
  const [user, setUser] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState([]); // State to store breadcrumbs
  const navigate = useNavigate(); // Initialize navigate for redirection
  const [users, setUsers] = useState([]); // New state for users
  const [filteredUsers, setFilteredUsers] = useState([]); // New state for filtered users
  const [companyInfo, setCompanyInfo] = useState(null);
  const [companyImage, setCompanyImage] = useState(null);
  const [locationGuidelines, setLocationGuidelines] = useState({});
  const [instructions, setInstructions] = useState("");
  const [note, setNote] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalCoords, setModalCoords] = useState([0, 0]);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal

  const [isModalOpen, setIsModalOpen] = useState(false);

  //geofencing
  const [geofencingEnabled, setGeofencingEnabled] = useState(false);
  const [geoFencingIncluded, setGeoFencingIncluded] = useState(false);
  const [geofences, setGeofences] = useState([]);
  const [editingFenceIndex, setEditingFenceIndex] = useState(null);
  const [fenceMode, setFenceMode] = useState("inbound");

  const handleFenceModeChange = (index) => (event) => {
    const newMode = event.target.value;

    const updatedGeofences = [...geofences];
    updatedGeofences[index].mode = newMode; // Update the mode for the specific geofence
    setGeofences(updatedGeofences); // Update the geofences state

    const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
    update(geofencingRef, { geofences: updatedGeofences });
  };

  // Fetch initial geofencing data

  useEffect(() => {
    // Listen for changes to geofencing data
    const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
    const unsubscribeGeofencing = onValue(geofencingRef, (snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        setGeofencingEnabled(data.enabled || false);
        setGeofences(data.geofences || []);
      } else {
        setGeofencingEnabled(false);
        setGeofences([]);
      }
    });

    return () => {
      unsubscribeGeofencing(); // Clean up the listener on unmount
    };
  }, [watchId]);

  useEffect(() => {
    // Listen for changes to the isGeoFenceIncluded flag
    const geoFenceIncludedRef = ref(
      database,
      `Watches/${watchId}/isGeoFenceIncluded`
    );
    const unsubscribeGeoFenceIncluded = onValue(
      geoFenceIncludedRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setGeoFencingIncluded(snapshot.val() || false);
        } else {
          setGeoFencingIncluded(false);
        }
      }
    );

    return () => {
      unsubscribeGeoFenceIncluded(); // Clean up the listener on unmount
    };
  }, [watchId]);

  const toggleGeofencing = async () => {
    const newState = !geofencingEnabled;

    const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
    const geoFenceAlarmRef = ref(database, `Watches/${watchId}`);

    // Update geofencing enabled state in Firebase
    await update(geofencingRef, { enabled: newState });

    // If disabling geofencing, clear the geoFenceAlarm
    if (!newState) {
      await update(geoFenceAlarmRef, { geoFenceAlarm: "" }); // Correctly updating at the root level
    }

    // If enabling for the first time, ensure a default geofencing entry exists
    if (newState && geofences.length === 0) {
      const defaultGeofence = {
        id: Date.now(),
        center: [0, 0],
        radius: 100,
        enabled: true,
        mode: "inbound",
      };
      await update(geofencingRef, { geofences: [defaultGeofence] });
    }
  };

  const addGeofence = async () => {
    if (geofences.length < 5) {
      const newGeofence = {
        id: Date.now(),
        center: [0, 0],
        radius: 100,
        enabled: true,
        mode: "inbound",
      };
      const updatedGeofences = [...geofences, newGeofence];
      setGeofences(updatedGeofences);

      const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
      await update(geofencingRef, { geofences: updatedGeofences });
    } else {
      alert("You can only add up to 5 geofences.");
    }
  };

  const toggleFence = async (index) => {
    const updatedGeofences = [...geofences];
    updatedGeofences[index].enabled = !updatedGeofences[index].enabled;
    setGeofences(updatedGeofences);

    const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
    await update(geofencingRef, { geofences: updatedGeofences });
  };

  const updateGeofence = async (index, updatedFence) => {
    const updatedGeofences = geofences.map((fence, i) =>
      i === index ? { ...fence, ...updatedFence } : fence
    );

    setGeofences(updatedGeofences);

    const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
    await update(geofencingRef, { geofences: updatedGeofences });

    alert("GeoFence Updated");
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [tempGeofence, setTempGeofence] = useState(null);

  const openEditModal = (index) => {
    const geofence = geofences[index];
    setEditingFenceIndex(index);
    setTempGeofence({
      ...geofence,
      // Set the initial center to the watch coordinates if they exist
      center: [
        watchLatitude || geofence.center[0],
        watchLongitude || geofence.center[1],
      ],
    });
    setModalOpen(true);
  };

  const closeFenceModal = () => {
    setModalOpen(false);
    setTempGeofence(null);
  };

  const handleSaveGeofence = async () => {
    const updatedGeofences = [...geofences];
    updatedGeofences[editingFenceIndex] = { ...tempGeofence };
    setGeofences(updatedGeofences);

    const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
    await update(geofencingRef, { geofences: updatedGeofences });
    alert("GeoFence Updated");
    closeFenceModal();
  };
  const handleMarkerDragEnd = (event) => {
    const { lat, lng } = event.target.getLatLng();
    setTempGeofence((prev) => ({ ...prev, center: [lat, lng] }));
  };

  const handleRadiusChange = (e) => {
    const value = e.target.value;

    // If the input is empty, set the radius to an empty string
    // Otherwise, set the radius with a minimum of 100
    setTempGeofence((prev) => ({
      ...prev,
      radius: value === "" ? "" : Math.max(0, Number(value)),
    }));
  };

  const deleteGeofence = async (index) => {
    // Create a new array by removing the geofence at the specified index
    const updatedGeofences = geofences.filter((_, i) => i !== index);

    // Update the state with the new list of geofences
    setGeofences(updatedGeofences);

    // Update the Firebase database
    const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
    await update(geofencingRef, { geofences: updatedGeofences });

    // Close the modal (if open) after deleting
    closeFenceModal();
    alert("GeoFence Deleted");
  };

  // Ensure the database is updated whenever geofences change
  useEffect(() => {
    if (geofencingEnabled) {
      const geofencingRef = ref(database, `Watches/${watchId}/geoFencing`);
      update(geofencingRef, { geofences });
    }
  }, [geofences, geofencingEnabled]);

  const [isGeoFenceClicked, setIsGeofenceClicked] = useState(false);
  const [isLocationTrackingClicked, setIsLocationTrackingClicked] =
    useState(false);

  const handleGeoFenceClick = () => {
    setIsGeofenceClicked(true);
    setIsLocationTrackingClicked(false);
  };

  const handleLocationTrackingClicked = () => {
    setIsLocationTrackingClicked(true);
    setIsGeofenceClicked(false);
  };

  const alertStyles = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    border: "1px solid #ccc",
    padding: "20px",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    zIndex: 1000,
    textAlign: "center",
    zIndex: "99999",
  };

  const buttonContainerStyles = {
    display: "flex",
    justifyContent: "space-around",
    marginTop: "15px",
    gap: "30px",
  };

  const buttonStyles = {
    padding: "10px 20px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#007BFF",
    color: "white",
    cursor: "pointer",
    width: "100%",
  };

  const [showAlert, setShowAlert] = useState(false); // State to control the alert visibility

  //

  const WatchesImages = [
    {
      id: "SM-L305F",
      url: "../Images/SM-L305F.png",
    },
    {
      id: "SM-L310",
      url: "../Images/SM-L310.png",
    },
    {
      id: "SM-L315F",
      url: "../Images/SM-L315F.png",
    },
    {
      id: "SM-L705F",
      url: "../Images/SM-L705F.png",
    },
    {
      id: "SM-R910",
      url: "../Images/SM-R910.png",
    },
    {
      id: "SM-R915F",
      url: "../Images/SM-R915F.png",
    },
    {
      id: "SM-R930",
      url: "../Images/SM-R930.png",
    },
    {
      id: "SM-R935F",
      url: "../Images/SM-R935F.png",
    },
    {
      id: "SM-R940",
      url: "../Images/SM-R940.png",
    },
    {
      id: "SM-R945F",
      url: "../Images/SM-R945F.png",
    },
  ];

  const handleSubscribeClick = () => {
    setIsModalOpen(true); // Open the modal when the button is clicked
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  // Toggle modal visibility
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };
  // Handle watch deletion
  const handleDeleteWatch = async () => {
    try {
      // Remove the watch from 'Watches' database path
      await remove(ref(database, `Watches/${watchId}`));

      // Find users that have this watchId and clear the watchId field
      const usersRef = ref(database, "users");
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const updates = {};
        snapshot.forEach((childSnapshot) => {
          const user = childSnapshot.val();
          if (user.watchId === watchId) {
            updates[`users/${childSnapshot.key}/watchId`] = null; // Clear watchId
          }
        });
        if (Object.keys(updates).length > 0) {
          await update(ref(database), updates); // Apply updates
        }
      }

      alert("Watch and associated user links deleted successfully.");
      setShowDeleteModal(false); // Close modal
      navigate("/watches"); // Redirect to watches list
    } catch (error) {
      console.error("Error deleting watch:", error);
      alert("An error occurred while deleting the watch.");
    }
  };

  const [startDate, setStartDate] = useState(
    dayjs().utc().subtract(1, "day").startOf("day").format("YYYY-MM-DD") // Start of yesterday in UTC
  );

  const [endDate, setEndDate] = useState(
    dayjs().utc().add(1, "day").endOf("day").format("YYYY-MM-DD") // End of tomorrow in UTC
  );

  // Filter breadcrumbs based on the selected date range
  const filteredBreadcrumbs = breadcrumbs.filter(([lat, lon, timestamp]) => {
    const date = dayjs.utc(timestamp).format("YYYY-MM-DD HH:mm:ss"); // Ensure timestamp is in the correct format
    return date >= startDate && date <= endDate; // Compare with formatted dates
  });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const watchRef = ref(database, `Watches/${watchId}`);
        onValue(watchRef, (snapshot) => {
          const watchDetails = snapshot.val();
          if (watchDetails) {
            const { companyId, alarmCode, locationId } = watchDetails;

            // Fetch company information
            const companyRef = ref(database, `Companies/${companyId}`);
            onValue(companyRef, (companySnapshot) => {
              const companyData = companySnapshot.val();
              if (companyData) {
                setCompanyInfo(companyData);
                setCompanyImage(companyData?.image || null); // Assuming company image URL is under "image"
              } else {
                console.error("Company data not found.");
              }
            });

            // Fetch location guidelines based on alarmCode
            const locationRef = ref(
              database,
              `locations/${locationId}/guidelines`
            );
            onValue(locationRef, (locationSnapshot) => {
              const guidelines = locationSnapshot.val();
              if (guidelines && guidelines[alarmCode]) {
                const instruction =
                  guidelines[alarmCode]?.instructions ||
                  "No specific instructions.";
                setInstructions(instruction);
                setLocationGuidelines(guidelines);
              } else {
                console.error("Location guidelines not found.");
              }
            });

            // Fetch the latest 3 notes for the given locationId
            const notesRef = ref(database, `locations/${locationId}/notes`);
            const latestNotesQuery = query(
              notesRef,
              orderByChild("timestamp"),
              limitToLast(3)
            );
            onValue(latestNotesQuery, (notesSnapshot) => {
              const notesData = notesSnapshot.val();
              if (notesData) {
                const notesArray = Object.keys(notesData).map((key) => ({
                  id: key,
                  ...notesData[key],
                }));
                // Reverse the array to get the most recent notes first
                setLocationGuidelines((prevGuidelines) => ({
                  ...prevGuidelines,
                  latestNotes: notesArray.reverse(), // Ensures most recent notes appear first
                }));
              } else {
                console.log("No notes found.");
              }
            });
          }
        });
      } catch (error) {
        console.error("Error fetching company/location info:", error);
      }
    };

    fetchCompanyInfo();
  }, [database, watchId]);

  const alarmOptions = [
    { code: "C", label: "Clear", color: "green" },
    { code: "G", label: "Gas Alarm", color: "red" },
    { code: "O", label: "Overdue Alarm", color: "orange" },
    { code: "R", label: "Remote Alarm", color: "blue" },
  ];

  useEffect(() => {
    // Fetch user details
    const userRef = ref(database, `users/${currentUser.uid}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      setUser(userData);

      // If role is 2, auto-fill company details
      if (userData.userRole === 2) {
        setFormValues((prevValues) => ({
          ...prevValues,
          companyName: userData.companyName,
        }));
      }
    });
  }, [currentUser.uid]);

  const fetchWatchDetail2 = async () => {
    try {
      const path = window.location.pathname;
      const id = path.split("/").pop();
      setWatchId(id);

      const watchRef = ref(database, `Watches/${id}`);
      const snapshot = await get(watchRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setWatchDetails(data);
        setFormValues({
          displayName: data.displayName || "",
          locationName: data.locationName || "",
          companyName: data.companyName || "",
          companyId: data.companyId || "",
          groupCode: data.groupCode || "",
          locationId: data.locationId || "",
          userId: data.userId || "",
          phoneNumber: data.phoneNumber || "",
          watchDisplayName: data.watchDisplayName || "",
          watchIsOnline: data.isOnline || "",
          watchLastOnline: data.lastOnline || "",
          watchDeviceName: data.deviceName || "",
        });

        console.log("this is the user id", formValues.userId);

        setSelectedCompany(data.companyId);
        if (
          (currentUser.role === 2 || currentUser.role === 3) &&
          currentUser.companyId !== data.companyId
        ) {
          // If role is 2 or 3 and the company's IDs don't match, redirect
          navigate("/unauthorized");
        }

        // Fetch location logs
        const logsRef = ref(database, `Breadcrumbs/${id}/logs/location`);
        onValue(logsRef, (snapshot) => {
          const logsData = snapshot.val();
          if (logsData) {
            const breadcrumbsList = Object.values(logsData)
              .map((log) => [
                parseFloat(log.latitude),
                parseFloat(log.longitude),
                dayjs
                  .utc(parseInt(log.timestamp))
                  .local()
                  .format("YYYY-MM-DD HH:mm:ss"), // Convert UTC timestamp to local time
                log.alarmCode,
              ])
              .filter(([lat, lon]) => !isNaN(lat) && !isNaN(lon)); // Ensure valid data

            // Limit to latest 10 breadcrumbs
            const latestBreadcrumbs = breadcrumbsList.slice(0);
            setBreadcrumbs(latestBreadcrumbs);
            console.log(breadcrumbs);
          } else {
            setBreadcrumbs([]);
          }
        });
      } else {
        console.log("No data available");
      }
    } catch (error) {
      console.error("Error fetching watch details:", error);
    }
  };

  useEffect(() => {
    const fetchWatchDetails = async () => {
      try {
        const path = window.location.pathname;
        const id = path.split("/").pop();
        setWatchId(id);

        const watchRef = ref(database, `Watches/${id}`);
        const snapshot = await get(watchRef);
        if (snapshot.exists()) {
          const data = snapshot.val();
          setWatchDetails(data);
          setFormValues({
            displayName: data.displayName || "",
            locationName: data.locationName || "",
            companyName: data.companyName || "",
            companyId: data.companyId || "",
            groupCode: data.groupCode || "",
            locationId: data.locationId || "",
            userId: data.userId || "",
            phoneNumber: data.phoneNumber || "",
            watchDisplayName: data.watchDisplayName || "",
          });

          console.log("this is the user id", formValues.userId);

          setSelectedCompany(data.companyId);
          if (
            (currentUser.role === 2 || currentUser.role === 3) &&
            currentUser.companyId !== data.companyId
          ) {
            // If role is 2 or 3 and the company's IDs don't match, redirect
            navigate("/unauthorized");
          }

          // Fetch location logs
          const logsRef = ref(database, `Breadcrumbs/${id}/logs/location`);
          onValue(logsRef, (snapshot) => {
            const logsData = snapshot.val();
            if (logsData) {
              const breadcrumbsList = Object.values(logsData)
                .map((log) => [
                  parseFloat(log.latitude),
                  parseFloat(log.longitude),
                  dayjs
                    .utc(parseInt(log.timestamp))
                    .local()
                    .format("YYYY-MM-DD HH:mm:ss"), // Convert UTC timestamp to local time
                  log.alarmCode,
                ])
                .filter(([lat, lon]) => !isNaN(lat) && !isNaN(lon)); // Ensure valid data

              // Limit to latest 10 breadcrumbs
              const latestBreadcrumbs = breadcrumbsList.slice(0);
              setBreadcrumbs(latestBreadcrumbs);
              console.log(breadcrumbs);
            } else {
              setBreadcrumbs([]);
            }
          });
        } else {
          console.log("No data available");
        }
      } catch (error) {
        console.error("Error fetching watch details:", error);
      }
    };

    fetchWatchDetails();
  }, [user]);

  useEffect(() => {
    if (user && user.role === 1) {
      const companiesRef = ref(database, "Companies");
      onValue(companiesRef, (snapshot) => {
        const companiesData = snapshot.val();
        const companyList = companiesData
          ? Object.keys(companiesData).map((key) => ({
              companyId: key,
              companyName: companiesData[key].companyName,
            }))
          : [];
        setCompanies(companyList);
      });
    }
  }, [user]);

  const handleAlarmCodeChange = async (code) => {
    // Check if the role is 3 and prevent the function from executing
    if (currentUser.role === 3) {
      alert("You do not have permission to change the alarm code.");
      return; // Exit the function early if the role is 3
    }

    try {
      const watchRef = ref(database, `Watches/${watchId}`);
      await update(watchRef, { alarmCode: code });
      console.log(`Alarm code updated to ${code} for watch ID: ${watchId}`);
      setWatchDetails((prev) => ({ ...prev, alarmCode: code }));

      // Prepare the log details
      const logDetails = {
        alarmCode: code,
        latitude: watchLatitude || "Unknown",
        longitude: watchLongitude || "Unknown",
        timestamp: Date.now(),
        userId: currentUser?.uid || "Unknown ID",
        userEmail: currentUser?.email || "Unknown Email",
      };

      // Log the update for the specific watch and globally
      await logUpdate(watchId, logDetails, watchDetails.locationId);
    } catch (error) {
      console.error("Error updating alarm code:", error);
    }
  };

  const confirmAlarmChange = (
    watchId,
    code,
    groupCode,
    companyId,
    locationId
  ) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to change the alarm code?"
    );
    if (isConfirmed) {
      handleAlarmCodeChange(watchId, code, groupCode, companyId, locationId);
    }
  };

  // Fetch locations and filter based on companyId
  useEffect(() => {
    const fetchLocations = async () => {
      const locationsRef = ref(database, "locations");
      onValue(locationsRef, (snapshot) => {
        const locationsData = snapshot.val();
        const locationList = locationsData
          ? Object.keys(locationsData).map((key) => ({
              locationId: key,
              ...locationsData[key],
            }))
          : [];
        setLocations(locationList);
      });
    };
    fetchLocations();
  }, []);

  useEffect(() => {
    // Filter locations based on the companyId
    const selectedCompanyId =
      user?.role === 1 ? selectedCompany : formValues.companyId;
    setFilteredLocations(
      locations.filter((location) => location.companyId === selectedCompanyId)
    );
  }, [selectedCompany, formValues.companyId, locations, user]);

  // Fetch users and filter based on companyId and locationId
  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = ref(database, "users");
      onValue(usersRef, (snapshot) => {
        const usersData = snapshot.val();
        const userList = usersData
          ? Object.keys(usersData).map((key) => ({
              userId: key,
              ...usersData[key],
            }))
          : [];
        setUsers(userList);
      });
    };
    fetchUsers();
  }, []);

  useEffect(() => {
    // Filter users based on companyId and locationId
    const selectedCompanyId =
      user?.role === 1 ? selectedCompany : formValues.companyId;
    const selectedLocationId = formValues.locationId;
    setFilteredUsers(
      users.filter(
        (user) =>
          user.companyId === selectedCompanyId &&
          (!selectedLocationId || user.locationId === selectedLocationId)
      )
    );
  }, [
    selectedCompany,
    formValues.companyId,
    formValues.locationId,
    users,
    user,
  ]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleCompanyChange = (e) => {
    const value = e.target.value;
    setSelectedCompany(value); // Set selected company to filter locations
    setFormValues((prev) => ({
      ...prev,
      locationId: "",
      locationName: "",
      groupCode: "",
      companyId: value, // Update companyId in form values
      companyName:
        companies.find((c) => c.companyId === value)?.companyName || "",
    }));
  };

  const handleLocationChange = (e) => {
    const locationId = e.target.value;
    const location = filteredLocations.find(
      (loc) => loc.locationId === locationId
    );
    setFormValues((prevValues) => ({
      ...prevValues,
      groupCode: "",
      locationName: location ? location.locationName : "",
      locationId: locationId,
    }));
  };

  const handleOwnerChange = (e) => {
    const value = e.target.value;
    const selectedUser = filteredUsers.find((user) => user.userId === value);
    setFormValues((prevValues) => ({
      ...prevValues,
      displayName: selectedUser ? selectedUser.displayName : "",
      userId: value,
    }));
  };

  const handleSave = async () => {
    try {
      console.log("Form Values before save:", formValues); // Debug line

      const watchRef = ref(database, `Watches/${watchId}`);
      await update(watchRef, formValues);
      console.log(`Watch details updated for watch ID: ${watchId}`);
      alert(`Watch details updated for watch ID: ${watchId}`);
      setWatchDetails((prev) => ({ ...prev, ...formValues }));
    } catch (error) {
      console.error("Error updating watch details:", error);
    }
  };

  if (!watchDetails) return <div>Loading...</div>;

  const {
    displayName,
    locationName,
    companyName,
    groupCode,
    alarmCode,
    watchLatitude,
    isSubscribed,
    planSelected,
    bill,
    subscribedDate,
    subscriptionEndDate,

    watchLongitude,
    watchIsOnline,
    watchLastOnline,
    watchDeviceName,
  } = watchDetails;

  const alarmCodeLabels = {
    C: "Cancel",
    G: "Green",
    O: "Orange",
    R: "Red",
  };

  const alarmCodeLabels2 = {
    C: "Idle",
    G: "Green",
    O: "Orange",
    R: "Red",
  };

  const subscriptionLabel = {
    true: "Active",
    false: "Unsubscribed",
  };

  const subscriptionColors = {
    false: "#939196",
    true: "#185519",
  };

  const alarmCodeColors = {
    C: "#3C3D37",
    G: "#185519",
    O: "#CD5C08",
    R: "#7D0A0A",
  };

  const alarmCodeColors2 = {
    C: "#939196",
    G: "#185519",
    O: "#CD5C08",
    R: "#7D0A0A",
  };

  // Define the custom icons
  const mapIcon = L.icon({
    iconUrl: mapIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const redIcon = L.icon({
    iconUrl: redWatchIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const orangeIcon = L.icon({
    iconUrl: orangeWatchIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const cancelledIcon = L.icon({
    iconUrl: cancelledIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const greenIcon = L.icon({
    iconUrl: greenIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const breadCrumbsIcon = L.icon({
    iconUrl: periodIcon,
    iconSize: [20, 20],
    iconAnchor: [8, 19],
    popupAnchor: [0, -19],
  });

  const redBreadCrumbsIcon = L.icon({
    iconUrl: redPeriodIcon,
    iconSize: [20, 20],
    iconAnchor: [8, 19],
    popupAnchor: [0, -19],
  });

  const orangeBreadCrumbsIcon = L.icon({
    iconUrl: orangePeriodIcon,
    iconSize: [20, 20],
    iconAnchor: [8, 19],
    popupAnchor: [0, -19],
  });

  const greenBreadCrumbsIcon = L.icon({
    iconUrl: greenPeriodIcon,
    iconSize: [20, 20],
    iconAnchor: [8, 19],
    popupAnchor: [0, -19],
  });

  const breadCrumbsIconFunc = (alarmCode) => {
    if (!alarmCode) {
      return breadCrumbsIcon; // Default if no alarm code is provided
    }

    switch (alarmCode) {
      case "C":
        return breadCrumbsIcon; // Specify your cancelled icon
      case "G":
        return greenBreadCrumbsIcon; // Specify your green icon
      case "R":
        return redBreadCrumbsIcon; // Specify your red icon
      case "O":
        return orangeBreadCrumbsIcon; // Specify your orange icon
      default:
        return breadCrumbsIcon; // Use the default icon for any other cases
    }
  };

  const getAlarmLabel = (alarmCode) => {
    switch (alarmCode) {
      case "C":
        return "Idle";
      case "G":
        return "Green";
      case "R":
        return "Red";
      case "O":
        return "Orange";
      default:
        return "Unknown";
    }
  };

  const warningIcon = L.icon({
    iconUrl: warningIconUrl,
    iconSize: [45, 45],
    iconAnchor: [19, 38],
    popupAnchor: [0, -38],
  });

  const getDeviceInfo = async () => {
    const userAgent = navigator.userAgent;
    const isPC = /Windows|Mac|Linux/.test(userAgent); // Detect if it's a PC
    const connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;
    let connectivity = connection ? connection.effectiveType : "Unknown";

    // If the device is a PC and the connection type is "4g", assume it's Wi-Fi
    if (isPC && connectivity === "4g") {
      connectivity = "Wi-Fi";
    }

    const browser = (() => {
      const ua = userAgent;
      if (ua.includes("Firefox")) return "Firefox";
      if (ua.includes("Edg")) return "Edge";
      if (ua.includes("Chrome")) return "Chrome";
      if (ua.includes("Safari") && !ua.includes("Chrome")) return "Safari";
      return "Unknown";
    })();

    const deviceBrand = /Mobile/.test(userAgent) ? "Mobile Device" : "PC";
    let batteryLevel = "(PC)";

    // If it's a mobile device, attempt to get battery info
    if (!isPC && navigator.getBattery) {
      const battery = await navigator.getBattery();
      batteryLevel = `${Math.round(battery.level * 100)}%`;
    }

    return {
      connectivityMode: connectivity, // Return the modified connectivity status
      deviceModel: deviceBrand,
      browser,
      batteryLevel,
    };
  };

  const logUpdate = async (watchId, logDetails, locationId) => {
    try {
      const deviceInfo = await getDeviceInfo();

      const locationRef = ref(database, `locations/${locationId}`);
      const locationSnapshot = await new Promise((resolve, reject) => {
        onValue(
          locationRef,
          (snapshot) => {
            resolve(snapshot.val());
          },
          {
            onlyOnce: true,
          }
        );
      });

      if (!locationSnapshot) {
        console.error(`Location with ID ${locationId} not found.`);
        return;
      }

      const { locationName, locationCode, companyName, latitude, longitude } =
        locationSnapshot;

      const extendedLogDetails = {
        ...logDetails,
        locationId,
        locationName: locationName || "Unknown",
        locationCode: locationCode || "Unknown",
        companyName: companyName || "Unknown",
        locationLatitude: latitude || "Unknown",
        locationLongitude: longitude || "Unknown",
        deviceInfo, // Include device information
      };

      // Log the alarm details inside the specific watch's log entry
      const alarmLogRef = ref(database, `Watches/${watchId}/logs/alarm`);
      await push(alarmLogRef, {
        ...extendedLogDetails, // Include all the details in the alarm log
      });

      // Log globally in the "logs" table under "logs/alarm"
      const globalLogRef = ref(database, `logs/alarm`);
      const globalLog = {
        ...extendedLogDetails,
        watchId, // Add the watchId to the global log for tracking
      };
      await push(globalLogRef, globalLog);
    } catch (error) {
      console.error("Error logging update:", error);
    }
  };

  // const icon =
  //   watchDetails.alarmCode && watchDetails.alarmCode !== "C"
  //     ? warningIcon
  //     : mapIcon;

  const icon = (() => {
    if (!watchDetails.alarmCode) {
      return mapIcon; // Default if no alarm code is provided
    }

    switch (watchDetails.alarmCode) {
      case "C":
        return cancelledIcon; // Specify your cancelled icon
      case "G":
        return greenIcon; // Specify your green icon
      case "R":
        return redIcon; // Specify your red icon
      case "O":
        return orangeIcon; // Specify your orange icon
      default:
        return cancelledIcon; // Use the warning icon for any other cases
    }
  })();

  const handleSendNote = () => {
    if (note.trim() !== "") {
      // Get the current position using the geolocation API
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLatitude = position.coords.latitude;
          const currentLongitude = position.coords.longitude;

          const newNote = {
            note: note,
            createdBy: currentUser.email, // Replace with the actual user identifier
            timestamp: new Date().toISOString(),
            userLatitude: currentLatitude,
            userLongitude: currentLongitude,
          };

          const noteRef = ref(
            database,
            `locations/${watchDetails.locationId}/notes`
          );

          // Push the new note to the database with a unique key
          push(noteRef, newNote)
            .then(() => {
              console.log("Note updated successfully");
              setNote(""); // Clear note input after sending
            })
            .catch((error) => {
              console.error("Error sending note:", error);
            });
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    }
  };

  // Function to open the modal with specific coordinates
  const openModalWithCoords = (lat, lng) => {
    console.log("Opening modal with coordinates:", lat, lng); // Add a log to check the values
    setModalCoords([lat, lng]);
    setModalIsOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    // Add 1 full day when the endDate is selected
    const adjustedEndDate = dayjs(e.target.value)
      .add(1, "day")
      .format("YYYY-MM-DD");
    setEndDate(adjustedEndDate);
  };

  // For display purposes, show 1 day earlier for the end date in the calendar
  const displayEndDate = dayjs(endDate).subtract(1, "day").format("YYYY-MM-DD");

  const isRole3 =
    currentUser.role === 3 ||
    currentUser.role === undefined ||
    currentUser.role === null ||
    currentUser.role === "";

  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Watch Information</h1>
            <div className="item">
              <div className="details">
                <div
                  className=""
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  {watchDetails.deviceName && (
                    <div style={{ flex: 1, padding: "0 10px" }}>
                      <img
                        src={
                          WatchesImages.find(
                            (image) => image.id === watchDetails.deviceName
                          )?.url || ""
                        }
                        alt={watchDetails.deviceName}
                        style={{
                          width: "100px",
                          height: "100px",
                          margin: "auto",
                          display: "block",
                        }}
                      />
                    </div>
                  )}
                  <div style={{ flex: 1, padding: "0 10px" }}>
                    <span className="itemKey">Watch Id:</span>
                    <h2 className="itemTitle">{watchId}</h2>
                    <div>
                      <div className="detailItem">
                        <span className="itemKey"> Status:</span>
                        <span
                          className="itemValue"
                          style={{
                            color: watchDetails.isOnline ? "green" : "red",
                            fontWeight: "normal",
                          }}
                        >
                          {watchDetails.isOnline ? "Online" : "Offline"}
                        </span>
                      </div>

                      {!watchDetails.isOnline && watchDetails.lastOnline && (
                        <div
                          className="detailItem"
                          style={{ marginTop: "5px" }}
                        >
                          <span className="itemKey">Last Online:</span>
                          <span className="itemValue">
                            {" "}
                            {new Date(
                              watchDetails.lastOnline
                            ).toLocaleDateString("en-GB", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            })}
                            <br />
                          </span>
                          <span className="itemKey">at </span>
                          <span className="itemValue">
                            {new Date(
                              watchDetails.lastOnline
                            ).toLocaleTimeString("en-GB", {
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true, // Enables AM/PM format
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Assigned to:</span>
                  <span className="itemValue">{displayName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Location:</span>
                  <span className="itemValue">{locationName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Company:</span>
                  <span className="itemValue">{companyName}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Group Code:</span>
                  <span className="itemValue">{groupCode}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Alarm Code:</span>
                  <span
                    className="itemValue"
                    style={{
                      backgroundColor: alarmCodeColors2[alarmCode] || "gray",
                      color: "white",
                      padding: "5px",
                      borderRadius: "4px",
                    }}
                  >
                    {alarmCodeLabels2[alarmCode] || "Unknown"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div className="alarmStatus">
              <div className="currentStatus">
                <h2>Current Alarm Status</h2>
                <div
                  className="statusLabel"
                  style={{
                    backgroundColor: alarmCodeColors2[alarmCode] || "gray",
                  }}
                >
                  {alarmCodeLabels2[alarmCode] || "Unknown"}
                </div>
              </div>
              <div className="alarmOptions">
                <h3>Set Alarm Status</h3>
                <div className="buttonGroup">
                  {Object.entries(alarmCodeLabels).map(([code, label]) => (
                    <button
                      key={code}
                      className="button"
                      style={{
                        backgroundColor: alarmCodeColors[code] || "gray",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        margin: "5px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => confirmAlarmChange(code)}
                      onMouseEnter={(e) =>
                        (e.target.style.backgroundColor = "black")
                      }
                      onMouseLeave={(e) =>
                        (e.target.style.backgroundColor =
                          alarmCodeColors[code] || "gray")
                      }
                    >
                      {label}
                    </button>
                  ))}
                </div>
              </div>
            </div>

            {/* Billing plan*/}
          </div>

          <div className="right">
            <div className="alarmStatus">
              <div className="currentStatus">
                <h2>Billing Status</h2>
                <div
                  className="statusLabel"
                  style={{
                    backgroundColor: subscriptionColors[isSubscribed] || "gray",
                  }}
                >
                  {subscriptionLabel[isSubscribed] || "Unsubscribed"}
                </div>
              </div>
              <div className="alarmOptions">
                <h3>Plan Information:</h3>
                <p style={{ fontWeight: "200" }}>
                  Selected Plan: <strong>{planSelected}</strong>
                </p>
                <p style={{ fontWeight: "200" }}>
                  <p>
                    Activation Date:{" "}
                    {subscribedDate
                      ? new Intl.DateTimeFormat("en-GB").format(
                          new Date(subscribedDate)
                        )
                      : "Not available"}
                  </p>
                  {/* <p style={{ fontWeight: "200" }}>
      Plan End Date:{" "}
      {subscriptionEndDate
        ? new Intl.DateTimeFormat("en-GB").format(new Date(subscriptionEndDate))
        : "Not available"}
    </p> */}
                </p>
                <p style={{ fontWeight: "200" }}>Current Bill: ${bill}</p>
              </div>

              <div className="alarmOptions">
                {currentUser.role === 1 || currentUser.role === 2 ? (
                  <div className="buttonGroup">
                    <button
                      className="button"
                      style={{
                        backgroundColor: "gray",
                        color: "white",
                        border: "none",
                        padding: "10px 20px",
                        margin: "5px",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      onClick={handleSubscribeClick} // Trigger modal open
                    >
                      {watchDetails?.isSubscribed
                        ? "Update Watch Plan"
                        : "Subscribe to a Plan"}
                    </button>

                    {/* Uncomment and customize the Cancel Plan button if needed */}
                    {/* <button
        className="button"
        style={{
          backgroundColor: "gray",
          color: "white",
          border: "none",
          padding: "10px 20px",
          margin: "5px",
          borderRadius: "5px",
          cursor: "pointer",
        }}
      >
        Cancel Plan
      </button> */}
                  </div>
                ) : null}
              </div>
              {isModalOpen && (
                <SubscribeToPlanModal
                  watchId={watchId}
                  watchCompanyId={watchDetails?.companyId}
                  watchLocationId={watchDetails?.locationId}
                  watchPlan={watchDetails?.planSelected}
                  watchIsSubscribed={watchDetails?.isSubscribed}
                  onClose={handleCloseModal}
                  fetchWatchDetail2={fetchWatchDetail2} // Pass the function as a prop
                />
              )}
            </div>

            {/* 
              
            
            */}
          </div>
        </div>
        <div className="bottom">
          <div
            className="editSection"
            style={{
              flex: 1,
            }}
          >
            <h1 className="title">Edit Watch Details</h1>
            <div className="editForm">
              {/* Watch Owner Dropdown */}
              <label>
                Owner:
                <select
                  value={formValues.userId}
                  onChange={handleOwnerChange}
                  style={{
                    height: "40px",
                    border: "1px solid gray",
                  }}
                  disabled={isRole3} // Disable for role 3
                >
                  <option value="" disabled>
                    Select Watch Owner
                  </option>
                  <option value="">Remove Watch Owner</option>
                  {filteredUsers.map((user) => (
                    <option key={user.userId} value={user.userId}>
                      {user.displayName}
                    </option>
                  ))}
                </select>
              </label>

              <label>
                Display Name:
                <input
                  type="text"
                  name="watchDisplayName"
                  value={formValues.watchDisplayName}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                  maxLength={12}
                />
              </label>

              <label>
                Company:
                <select
                  value={formValues.companyId} // Set default value to formValues.companyId
                  onChange={handleCompanyChange}
                  required
                  disabled={currentUser.role !== 1} // Disable dropdown if user role is not 1
                  style={{
                    height: "40px",
                    border: "1px solid gray",
                  }}
                >
                  <option value="" disabled>
                    Select Company
                  </option>{" "}
                  {/* Clickable but not valid */}
                  <option value="">Dissociate Watch from Company</option>{" "}
                  {/* Dissociate option */}
                  {companies.map((company) => (
                    <option key={company.companyId} value={company.companyId}>
                      {company.companyName}
                    </option>
                  ))}
                </select>
              </label>

              {/* Location Dropdown */}
              <label>
                Location:
                <select
                  value={formValues.locationId}
                  onChange={handleLocationChange}
                  style={{
                    height: "40px",
                    border: "1px solid gray",
                  }}
                  disabled={isRole3} // Disable for role 3
                >
                  <option value="">Select Location</option>
                  <option value="">Dissociate Watch from Location</option>{" "}
                  {filteredLocations.map((location) => (
                    <option
                      key={location.locationId}
                      value={location.locationId}
                    >
                      {location.locationName}
                    </option>
                  ))}
                </select>
              </label>

              <label>
                Phone Number:
                <input
                  type="tel"
                  name="phoneNumber"
                  value={
                    formValues.phoneNumber ? `+${formValues.phoneNumber}` : ""
                  }
                  onChange={(e) => {
                    // Allow only numeric characters (0-9)
                    const numericValue = e.target.value.replace(/[^0-9]/g, "");

                    // Call handleInputChange with the updated numeric value (no '+' added here)
                    handleInputChange({
                      target: { name: "phoneNumber", value: numericValue },
                    });
                  }}
                  disabled={isRole3} // Disable for role 3
                  required
                  maxLength={18}
                />
              </label>

              <label>
                Group Code:
                <input
                  type="text"
                  name="groupCode"
                  value={formValues.groupCode}
                  onChange={handleInputChange}
                  disabled={isRole3} // Disable for role 3
                  maxLength={2}
                />
              </label>

              <button className="saveButton" onClick={handleSave}>
                Update Watch
              </button>
              <button
                style={{
                  color: "red",
                }}
                onClick={handleDeleteClick}
              >
                Delete Watch
              </button>
            </div>
            {showDeleteModal && (
              <div className="deleteModal">
                <div className="deleteModalContent">
                  <p>Are you sure you want to delete this Watch?</p>
                  <p
                    style={{
                      color: "gray",
                      fontSize: "12px",
                    }}
                  >
                    Deleting this Watch will remove the watch in its associated
                    user
                  </p>

                  <p style={{ color: "red", fontSize: "12px" }}>
                    {" "}
                    Please be aware: Deleting a Watch is irreversible and cannot
                    be undone.{" "}
                  </p>
                  <div className="">
                    <button
                      className="cancel"
                      onClick={() => {
                        handleDeleteWatch();
                        alert("Watch Deleted");
                      }}
                    >
                      Yes
                    </button>
                    <button
                      className="confirm"
                      onClick={() => setShowDeleteModal(false)}
                    >
                      No
                    </button>
                  </div>
                </div>
              </div>
            )}
            <style jsx>{`
              .deleteModal {
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background: rgba(0, 0, 0, 0.5);
                display: flex;
                align-items: center;
                justify-content: center;
                z-index: 1000;
              }

              .deleteModalContent {
                background-color: white;
                padding: 20px;
                border-radius: 8px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
                width: 300px;
                text-align: center;
              }

              .deleteModal p {
                font-size: 18px;
                margin-bottom: 20px;
                color: #333; /* Dark text for better readability */
              }

              .deleteModal button {
                margin: 5px;
                padding: 10px 20px;
                font-size: 16px;
                border: none;
                border-radius: 5px;
                cursor: pointer;
              }

              .deleteModal button.confirm {
                background-color: #e74c3c;
                color: white;
              }

              .deleteModal button.cancel {
                background-color: #95a5a6;
                color: white;
              }

              .deleteModalButtons {
                display: flex;
                justify-content: space-between;
              }
            `}</style>
          </div>

          <div className="mapSection" style={{ flex: 2 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: "20px",
                paddingBottom: "10px",
              }}
            >
              <Button
                style={{
                  width: "100%",
                }}
                variant={isLocationTrackingClicked ? "contained" : "outlined"}
                onClick={handleLocationTrackingClicked}
              >
                Filter Location Tracking History
              </Button>

              <Button
                style={{
                  width: "100%",
                }}
                variant={isGeoFenceClicked ? "contained" : "outlined"}
                onClick={handleGeoFenceClick}
              >
                GeoFencing Configuration
              </Button>
            </div>
            {isLocationTrackingClicked && (
              <>
                <p> Filter Location Tracking History by Date</p>
                <br />
                <div
                  style={{ marginBottom: "1em", display: "flex", gap: "1em" }}
                >
                  <FormControl variant="outlined">
                    <TextField
                      label="From"
                      type="date"
                      value={startDate}
                      onChange={handleStartDateChange}
                      InputLabelProps={{
                        shrink: true, // Keeps the label at the top
                      }}
                      sx={{ width: 220 }}
                      size="small" // Reduces the height
                    />
                  </FormControl>
                  <FormControl variant="outlined">
                    <TextField
                      label="To"
                      type="date"
                      value={displayEndDate} // Display the end date minus 1 day
                      onChange={handleEndDateChange}
                      InputLabelProps={{
                        shrink: true, // Keeps the label at the top
                      }}
                      sx={{ width: 220 }}
                      size="small" // Reduces the height
                    />
                  </FormControl>
                </div>
              </>
            )}

            {isGeoFenceClicked && (
              <>
                <div style={{ marginBottom: "1em", marginTop: "1em" }}>
                  <p>Geofencing</p>
                  <Switch
                    checked={geoFencingIncluded ? geofencingEnabled : false} // Always false if not included
                    onChange={async () => {
                      if (!geoFencingIncluded) {
                        setShowAlert(true);
                        return;
                      }

                      await toggleGeofencing(); // Call the function that updates Firebase
                    }}
                  />

                  <span>
                    Geofencing:{" "}
                    {geoFencingIncluded
                      ? geofencingEnabled
                        ? "Enabled"
                        : "Disabled"
                      : "Not Available"}
                  </span>
                  {/* Modal-like alert */}
                  {showAlert && (
                    <div style={alertStyles}>
                      <p
                        style={{
                          color: "red",
                          marginBottom: "30px",
                        }}
                      >
                        Geofencing is not included in your current plan.
                      </p>
                      <div style={buttonContainerStyles}>
                        <button
                          style={buttonStyles}
                          onClick={() => {
                            handleSubscribeClick(); // Trigger subscription plan function
                            setShowAlert(false); // Close alert
                          }}
                        >
                          View Plans
                        </button>
                        <button
                          style={{
                            padding: "10px 20px",
                            border: "none",
                            borderRadius: "5px",
                            backgroundColor: "gray",
                            color: "white",
                            cursor: "pointer",
                            width: "100%",
                          }}
                          onClick={() => setShowAlert(false)} // Close alert
                        >
                          Go Back
                        </button>
                      </div>
                    </div>
                  )}
                  {geoFencingIncluded && geofencingEnabled && (
                    <Box display="flex" alignItems="center" gap={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={addGeofence}
                      >
                        Add Geofence
                      </Button>

                      <Tooltip
                        title="To update this timer, go to Locations > Select a Location > Update Geofence Timer"
                        arrow
                      >
                        <FormControl size="small" sx={{ minWidth: 120 }}>
                          <InputLabel sx={{ color: "gray" }}>
                            Geofence Timer
                          </InputLabel>
                          <Select
                            value={watchDetails.geoFenceTimer}
                            disabled
                            sx={{
                              color: "gray",
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "gray",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "gray",
                              },
                            }}
                             label="GeoFence Timer"
                          >
                            <MenuItem value={watchDetails.geoFenceTimer}>
                              {watchDetails.geoFenceTimer} Minutes
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Tooltip>
                    </Box>
                  )}
                </div>

                {geofencingEnabled &&
                  geoFencingIncluded &&
                  geofences.map((fence, index) => (
                    <div key={fence.id} style={{ marginBottom: "1em" }}>
                      <Switch
                        checked={fence.enabled}
                        onChange={() => toggleFence(index)}
                      />

                      <span>
                        Geofence {index + 1}{" "}
                        <span style={{ color: "gray", fontSize: "10px" }}>
                          ({index + 1}/5)
                        </span>
                        : {fence.enabled ? "Enabled" : "Disabled"}
                      </span>

                      <Button
                        variant="outlined"
                        onClick={() => openEditModal(index)}
                        style={{ marginLeft: "10px", marginRight: "10px" }}
                      >
                        Edit in Map
                      </Button>

                      <FormControl
                        variant="outlined"
                        size="small"
                        style={{ minWidth: "150px", marginRight: "10px" }}
                      >
                        <InputLabel>Mode</InputLabel>
                        <Select
                          value={fence.mode}
                          onChange={handleFenceModeChange(index)} // Correctly pass index
                          label="Mode"
                        >
                          <MenuItem value="inbound">Inbound</MenuItem>
                          <MenuItem value="outbound">Outbound</MenuItem>
                        </Select>
                      </FormControl>

                      {/* geofence */}

                      {editingFenceIndex === index ? (
                        <div
                          style={{
                            display: "flex",
                            gap: "1em",
                            marginTop: "0.5em",
                          }}
                        >
                          <TextField
                            label="Latitude"
                            type="text"
                            value={
                              tempGeofence?.center
                                ? tempGeofence.center[0]
                                : fence.center[0]
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (!newValue || !isNaN(newValue)) {
                                setTempGeofence((prev) => ({
                                  ...prev,
                                  center: [
                                    newValue === "" ? "" : Number(newValue),
                                    prev?.center
                                      ? prev.center[1]
                                      : fence.center[1],
                                  ],
                                }));
                              }
                            }}
                            size="small"
                          />
                          <TextField
                            label="Longitude"
                            type="text"
                            value={
                              tempGeofence?.center
                                ? tempGeofence.center[1]
                                : fence.center[1]
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (!newValue || !isNaN(newValue)) {
                                setTempGeofence((prev) => ({
                                  ...prev,
                                  center: [
                                    prev?.center
                                      ? prev.center[0]
                                      : fence.center[0],
                                    newValue === "" ? "" : Number(newValue),
                                  ],
                                }));
                              }
                            }}
                            size="small"
                          />
                          <TextField
                            label="Radius(Meter)"
                            type="text"
                            value={
                              tempGeofence?.radius !== undefined
                                ? tempGeofence.radius
                                : fence.radius
                            }
                            onChange={(e) => {
                              const newValue = e.target.value;
                              if (!newValue || !isNaN(newValue)) {
                                setTempGeofence((prev) => ({
                                  ...prev,
                                  radius:
                                    newValue === "" ? "" : Number(newValue),
                                }));
                              }
                            }}
                            size="small"
                          />

                          <Button
                            variant="contained"
                            onClick={() => {
                              updateGeofence(editingFenceIndex, tempGeofence);
                              setEditingFenceIndex(null);
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => setEditingFenceIndex(null)} // Cancel action to close without saving
                            style={{
                              backgroundColor: "#B0B0B0",
                              color: "white",
                            }} // Custom gray color
                          >
                            Cancel
                          </Button>

                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              if (
                                window.confirm(
                                  "Are you sure you want to delete this geofence?"
                                )
                              ) {
                                deleteGeofence(index); // Call your delete function here
                              }
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      ) : (
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setTempGeofence({ ...fence }); // Ensure we create a fresh copy
                            setEditingFenceIndex(index);
                          }}
                        >
                          Edit
                        </Button>
                      )}

                      {/* geofence */}
                    </div>
                  ))}
              </>
            )}

            <MapContainer
              center={[
                watchLatitude || breadcrumbs[0]?.[0] || 0,
                watchLongitude || breadcrumbs[0]?.[1] || 0,
              ]}
              zoom={13}
              style={{ height: "600px", width: "100%" }}
            >
              <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              />

              {watchLatitude && watchLongitude && (
                <Marker position={[watchLatitude, watchLongitude]} icon={icon}>
                  <Popup>
                    {companyImage && (
                      <div className="company-image-container">
                        <img
                          src={companyImage}
                          alt="Company Logo"
                          style={{
                            width: "100px",
                            height: "auto",
                            margin: "0 auto",
                            display: "block",
                          }}
                        />
                      </div>
                    )}
                    <b>{formValues.displayName}</b> <br />
                    WatchId: {watchId} <br />
                    Phone Number: {formValues.phoneNumber} <br />
                    Alarm Code: {formValues.alarmCode} <br />
                    Company: {formValues.companyName} <br />
                    Location: {formValues.locationName} <br />
                    Coordinates: {watchLatitude},{watchLongitude} <br />
                    <br />
                    <br />
                    <b>Response Required:</b> <br />
                    {instructions} <br />
                    <br />
                    Police Number: {locationGuidelines.policeNumber ||
                      "N/A"}{" "}
                    <br />
                    Ambulance Number:{" "}
                    {locationGuidelines.ambulanceNumber || "N/A"} <br />
                    <br />
                    <textarea
                      style={{
                        width: "100%",
                      }}
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Add a note"
                    ></textarea>
                    <button
                      style={{
                        background: "black",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={() => handleSendNote(watchId)}
                    >
                      Send
                    </button>
                    <br />
                    <br />
                    <b>Recent Notes:</b> <br />
                    {locationGuidelines.latestNotes &&
                    locationGuidelines.latestNotes.length > 0 ? (
                      <div style={{ maxHeight: "100px", overflowY: "auto" }}>
                        {" "}
                        {/* Set fixed height and scroll */}
                        {locationGuidelines.latestNotes.map((noteItem) => (
                          <div
                            key={noteItem.id}
                            style={{
                              padding: "5px",
                              border: "0.5px solid black",
                              borderRight: "none",
                              borderLeft: "none",
                              borderBottom: "none",
                            }}
                          >
                            <div>Note: {noteItem.note}</div>
                            <div>By: {noteItem.createdBy}</div>
                            <div>
                              Date:{" "}
                              {new Date(noteItem.timestamp).toLocaleString()}
                            </div>
                            Location:{" "}
                            <a
                              href="#"
                              onClick={() =>
                                openModalWithCoords(
                                  noteItem.userLatitude,
                                  noteItem.userLongitude
                                )
                              }
                            >
                              {noteItem.userLatitude}, {noteItem.userLongitude}
                            </a>
                            {/* Modal for displaying the map */}
                            <Modal
                              isOpen={modalIsOpen}
                              onRequestClose={closeModal}
                              contentLabel="Map Modal"
                              style={{
                                overlay: {
                                  zIndex: 999999900, // Set a high z-index for the overlay
                                  backgroundColor: "rgba(0, 0, 0, 0.75)", // Darken the background
                                },
                                content: {
                                  width: "600px",
                                  height: "400px",
                                  margin: "auto",
                                  padding: "0",
                                  border: "none",
                                  borderRadius: "10px",
                                },
                              }}
                            >
                              <>
                                {/* Close button for modal */}
                                <button
                                  onClick={closeModal}
                                  style={{
                                    position: "absolute",
                                    top: "10px",
                                    right: "10px",
                                    background: "black",
                                    color: "white",
                                    border: "none",
                                    padding: "5px 10px",
                                    cursor: "pointer",
                                  }}
                                >
                                  Close
                                </button>

                                {/* Leaflet Map in Modal */}
                                <MapContainer
                                  center={modalCoords}
                                  zoom={15}
                                  style={{ height: "100%", width: "100%" }}
                                >
                                  <TileLayer
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                  />
                                  <Marker
                                    position={modalCoords}
                                    icon={mapIconUrl}
                                  />
                                </MapContainer>
                              </>
                            </Modal>
                            <br />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div>No notes available</div>
                    )}
                  </Popup>
                </Marker>
              )}

              {filteredBreadcrumbs.map(
                ([lat, lon, timestamp, alarm], index) => (
                  <Marker
                    key={index}
                    position={[lat, lon]}
                    icon={breadCrumbsIconFunc(alarm)}
                  >
                    <Popup>
                      <div>Location: {index + 1}</div>
                      <div>Alarm Code: {getAlarmLabel(alarm)}</div>
                      <div>
                        Date & Time:{" "}
                        {dayjs.utc(timestamp).format("DD/MM/YYYY HH:mm:ss")}
                      </div>
                    </Popup>
                  </Marker>
                )
              )}
              {filteredBreadcrumbs.length > 0 && (
                <Polyline
                  positions={filteredBreadcrumbs.map(([lat, lon]) => [
                    lat,
                    lon,
                  ])}
                  color="yellow"
                />
              )}
              {geofencingEnabled &&
                geofences.map(
                  (fence, index) =>
                    fence.enabled && (
                      <Circle
                        key={index}
                        center={fence.center}
                        radius={fence.radius}
                      />
                    )
                )}
            </MapContainer>

            <Modal open={modalOpen} onClose={closeFenceModal}>
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  backgroundColor: "white",
                  padding: "20px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  width: "80%",
                }}
              >
                <h2>Edit Geofence</h2>
                <div style={{ marginBottom: "10px" }}>
                  <label>
                    Radius (meters):{" "}
                    <input
                      type="number"
                      value={tempGeofence?.radius || ""}
                      onChange={handleRadiusChange}
                      min="0"
                    />
                  </label>
                </div>
                {tempGeofence && (
                  <MapContainer
                    center={tempGeofence.center}
                    zoom={13}
                    style={{ height: "500px", width: "100%" }}
                  >
                    <TileLayer
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker
                      position={tempGeofence.center}
                      draggable
                      eventHandlers={{
                        dragend: handleMarkerDragEnd,
                      }}
                      icon={mapIcon}
                    />
                    <Circle
                      center={tempGeofence.center}
                      radius={tempGeofence.radius}
                    />
                  </MapContainer>
                )}
                <div style={{ marginTop: "10px" }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSaveGeofence}
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                  <Button variant="outlined" onClick={closeFenceModal}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
        <div>
          <SingleWatchLogDataTable />
        </div>
      </div>
    </div>
  );
};

export default SingleWatch;
