import { useContext, useState, useEffect } from "react";
import "./login.scss";
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  sendEmailVerification,
  fetchSignInMethodsForEmail,
  signInWithPhoneNumber,
  RecaptchaVerifier,
  PhoneAuthProvider,
  updatePhoneNumber,
  linkWithCredential,
} from "firebase/auth";
import { auth, database } from "../../firebase";
import { ref, get, update } from "firebase/database";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import WGLogoLink from "../../Images/WGLOGO.png";
import bcrypt from 'bcryptjs';
import { updatePassword } from "firebase/auth";

const Login = () => {
  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [verificationError, setVerificationError] = useState(false);
  const [showResetModal, setShowResetModal] = useState(false);
  const [resetEmail, setResetEmail] = useState("");
  const [resetEmailError, setResetEmailError] = useState("");
  const [resetEmailSent, setResetEmailSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [showPhoneVerificationModal, setShowPhoneVerificationModal] =
    useState(false);
  const [phoneVerificationCode, setPhoneVerificationCode] = useState("");
  const [phoneVerificationError, setPhoneVerificationError] = useState("");
  const [resendCooldown, setResendCooldown] = useState(false);
  const [timer, setTimer] = useState(30);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [isSendingVerification, setIsSendingVerification] = useState(false);
  const [showPasswordUpdateModal, setShowPasswordUpdateModal] = useState(false);
  const [password2, setPassword2] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error2, setError2] = useState("");
  const [success, setSuccess] = useState(false);

  const navigate = useNavigate();
  const { dispatch } = useContext(AuthContext);

  const [phoneNumber, setPhoneNumber] = useState(null); // Add state for storing phone number

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Attempt to sign in with email and password
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;

      // Check if email is verified
      // if (!user.emailVerified) {
      //   setVerificationError(true);
      //   await signOut(auth); // Sign out if email is not verified
      //   console.log("Logged out due to unverified email.");
      // } else {
      // Fetch the user's data from Realtime Database
      const userRef = ref(database, `users/${user.uid}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        const userData = userSnapshot.val();
        let userPhoneNumber = userData.phoneNumber; // Retrieve phone number from Realtime Database

        // Ensure the phone number has the '+' sign for consistency
        if (!userPhoneNumber.startsWith("+")) {
          userPhoneNumber = `+${userPhoneNumber}`;
        }

        setPhoneNumber(userPhoneNumber); // Store the phone number in state

        const userRole = userData.role;
        const displayName = userData.displayName || "No Name";
        const companyId = userData.companyId;

        // Check if the phone number in Firebase Authentication matches the one from the Realtime Database
        let phoneNumberVerified = false;
        if (user.phoneNumber) {
          // Ensure the phone number in Firebase Auth is formatted correctly
          const formattedPhoneNumber = user.phoneNumber.startsWith("+")
            ? user.phoneNumber
            : `+${user.phoneNumber}`;

          phoneNumberVerified = formattedPhoneNumber === userPhoneNumber;
        }
        console.log("Firebase Auth Phone Number: ", user.phoneNumber);
        console.log("Database Phone Number: ", userPhoneNumber);

        // If phone number is not verified in Firebase Auth, show the phone verification modal
        if (!phoneNumberVerified) {
          setShowPhoneVerificationModal(true);
        } else if (
          userData.firstTimeLogIn === true ||
          userData.firstTimeLogIn === undefined
        ) {
          setShowPasswordUpdateModal(true);
        } else {
          // Dispatch login action if all verifications pass
          dispatch({
            type: "LOGIN",
            payload: {
              uid: user.uid,
              email: user.email,
              displayName: displayName,
              role: userRole,
              companyId: companyId,
            },
          });

          navigate("/"); // Navigate to the home page
        }
      } else {
        console.error("No user data found in Realtime Database!");
      }

      console.log(showPasswordUpdateModal);
      //    }
    } catch (err) {
      console.error("Login error: ", err);
  
      if (err.code === "auth/too-many-requests") {
        alert("Too many login attempts. Please try again later.");
      } else {
        setError(true);
      }
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (password2 !== confirmPassword) {
      setError2("Passwords do not match.");
      return;
    }
  
    if (
      !/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,20}$/.test(
        password2
      )
    ) {
      setError2("Password does not meet the requirements.");
      return;
    }
  
    setLoading(true);
    try {
      // Encrypt password before updating
      console.log("Before hashing:", password2); // Log password before hashing
      const hashedPassword = await bcrypt.hash(password2, 10); // Hash password with bcrypt
      console.log("Hashed password:", hashedPassword); // Log the hashed password
  
      // Update password in Firebase Authentication (using plain password)
      await updatePassword(auth.currentUser, password2); // Firebase will encrypt it internally
  
      // Save hashed password in the Firebase Realtime Database
      const userRef = ref(database, `users/${auth.currentUser.uid}`);
      await update(userRef, {
        password: hashedPassword, // Save hashed password to DB
        firstTimeLogIn: false,
      });
  
      setSuccess(true);
      setShowPasswordUpdateModal(false); // Close modal after success
      alert(
        "Your password has been successfully updated. Please log in again using your new password."
      );
      setPassword("");
    } catch (error) {
      setError2("Error updating password: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  

  // const handleResendEmail = async () => {
  //   if (resendCooldown) return; // Prevent multiple clicks during cooldown

  //   try {
  //     const userCredential = await signInWithEmailAndPassword(
  //       auth,
  //       email,
  //       password
  //     );
  //     const user = userCredential.user;

  //     if (!user.emailVerified) {
  //       // Resend the email verification if the user's email is not verified
  //       await sendEmailVerification(user);
  //       alert("Verification email sent!");

  //       // Start cooldown timer
  //       setResendCooldown(true);
  //       setTimer(30);
  //       const countdown = setInterval(() => {
  //         setTimer((prev) => {
  //           if (prev === 1) {
  //             clearInterval(countdown);
  //             setResendCooldown(false);
  //           }
  //           return prev - 1;
  //         });
  //       }, 1000);
  //     }
  //   } catch (error) {
  //     console.error("Error sending verification email:", error);
  //     alert("There was an issue sending the verification email.");
  //   }
  // };

  //newpasswordmodal

  // Check if user needs to update password
  useEffect(() => {
    if (showPasswordUpdateModal && auth.currentUser) {
      const userRef = ref(database, `users/${auth.currentUser.uid}`);
      get(userRef).then((snapshot) => {
        const userData = snapshot.val();
        if (
          userData &&
          (userData.firstTimeLogIn === true ||
            userData.firstTimeLogIn === undefined)
        ) {
          setShowPasswordUpdateModal(true);
        }
      });
    }
  }, [showPasswordUpdateModal]);

  const handleResendPhoneVerification = async () => {
    if (resendCooldown || isSendingVerification) return;
    setIsSendingVerification(true); // Set loading state

    // Ensure phoneNumber is properly formatted
    let userPhoneNumber = phoneNumber;
    if (!userPhoneNumber.startsWith("+")) {
      userPhoneNumber = `+${userPhoneNumber}`;
    }

    try {
      // Check if reCAPTCHA is already rendered
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              console.log("reCAPTCHA verified:", response);
            },
          }
        );
      }

      // Request phone number verification
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        userPhoneNumber,
        window.recaptchaVerifier
      );
      window.confirmationResult = confirmationResult;
      alert("Verification code sent!");
      setVerificationCodeSent(true);

      // Start cooldown timer
      setResendCooldown(true);
      setTimer(30);
      const countdown = setInterval(() => {
        setTimer((prev) => {
          if (prev === 1) {
            clearInterval(countdown);
            setResendCooldown(false);
          }
          return prev - 1;
        });
      }, 1000);
    } catch (error) {
      console.error("Error sending phone verification code:", error);
      alert(
        "There was an issue sending the verification code. Please contact your admin to make sure the phone number is valid"
      );
    } finally {
      setIsSendingVerification(false); // Reset loading state
    }
  };

  const handlePhoneVerification = async () => {
    if (!phoneVerificationCode) {
      setPhoneVerificationError("Please enter the verification code.");
      return;
    }

    if (!window.confirmationResult) {
      setPhoneVerificationError("Phone verification has not been initiated.");
      return;
    }

    try {
      const confirmationResult = window.confirmationResult;
      const credential = PhoneAuthProvider.credential(
        confirmationResult.verificationId,
        phoneVerificationCode
      );

      const user = auth.currentUser;
      if (user) {
        await updatePhoneNumber(user, credential);
        console.log("Phone number updated successfully in Firebase Auth.");
        alert("Phone number verified successfully!");
        setShowPhoneVerificationModal(false); // Close modal on success
        handleLogin();
      }
    } catch (error) {
      if (error.code === "auth/account-exists-with-different-credential") {
        console.error("Phone number already linked to a different account.");

        // Fetch existing sign-in methods for the account
        const email = error.customData?.email; // Get email from error data (if available)
        if (email) {
          const existingMethods = await fetchSignInMethodsForEmail(auth, email);
          console.log("Existing sign-in methods:", existingMethods);

          alert(
            `This phone number is linked to an existing account. Please sign in using: ${existingMethods.join(
              ", "
            )}`
          );
        }
      } else {
        setPhoneVerificationError(
          "Invalid verification code or another error occurred."
        );
        console.error("Error verifying phone number:", error);
      }
    }
  };

  const handleResetPassword = () => {
    if (!resetEmail) {
      setResetEmailError("Please enter your email.");
      return;
    }

    sendPasswordResetEmail(auth, resetEmail)
      .then(() => {
        setResetEmailSent(true);
        setResetEmailError("");
        setShowResetModal(false);
        alert("Password reset instructions have been successfully sent to your email address. Please check your inbox.");
      })
      .catch((error) => {
        console.error("Error resetting password:", error);
        setResetEmailError("Failed to send reset email. Please try again.");
        alert("Failed to send password reset email. Please try again later.");
      });
  };

  return (
    <div className="login" style={{ display: "flex", flexDirection: "column" }}>
      <img
        src={WGLogoLink}
        alt="WG Logo"
        className="logo"
        style={{ maxHeight: "500px", width: "500px" }}
      />
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <div style={{ position: "relative" }}>
          <input
            type={showPassword ? "text" : "password"}
            placeholder="password"
            value={password} // Bind to `password` state
            onChange={(e) => setPassword(e.target.value)}
          />
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              position: "absolute",
              right: "10px",
              top: "50%",
              transform: "translateY(-50%) translateX(30%)",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </button>
        </div>
        <button className="button" type="submit">
          Login
        </button>
        {error && <span>Invalid Credentials</span>}
        {/* {verificationError && (
          <div>
            <span>Please check your email to verify your account</span>
            <button
              onClick={handleResendEmail}
              style={{ marginTop: "10px", marginLeft: "10px" }}
              disabled={resendCooldown} // Disable button during cooldown
            >
              {resendCooldown
                ? `Resend in ${timer}s`
                : "Resend Email Confirmation"}
            </button>
          </div>
        )}
        {resetEmailSent && (
          <span style={{ color: "#6A9C89" }}>Password reset email sent!</span>
        )} */}
        <span
          style={{
            cursor: "pointer",
            color: "#3C3D37",
            marginTop: "10px",
          }}
          onClick={() => setShowResetModal(true)}
        >
          Forgot Password?
        </span>
      </form>

      {/* Password Reset Modal */}
      {showResetModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // Ensure it appears above other content
          }}
        >
          <div
            className="modal"
            style={{
              backgroundColor: "white",
              width: "500px",
              height: "auto",
              padding: "20px",
              borderRadius: "8px", // Optional: adds rounded corners
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: adds shadow for better visibility
              position: "absolute", // Added for better centering
              top: "50%", // Position at the center
              left: "50%", // Position at the center
              transform: "translate(-50%, -50%)", // Adjust to truly center it
            }}
          >
            <p style={{ marginBottom: "10px" }}>Reset Your Password</p>
            <input
              type="text"
              placeholder="Enter your email"
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            {resetEmailError && (
              <span style={{ color: "red" }}>{resetEmailError}</span>
            )}
            <button className="button1" onClick={handleResetPassword}>
              Send Reset Instructions to Email
            </button>
            <button
              className="button2"
              onClick={() => setShowResetModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Phone Number Verification Modal */}
      {showPhoneVerificationModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000, // Ensure it appears above other content
          }}
        >
          <div
            className="modal"
            style={{
              backgroundColor: "rgba(255, 255, 255, 0.8)",

              width: "700px",
              height: "auto",
              padding: "20px",
              borderRadius: "8px", // Optional: adds rounded corners
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // Optional: adds shadow for better visibility
              position: "absolute", // Added for better centering
              top: "50%", // Position at the center
              left: "50%", // Position at the center
              transform: "translate(-50%, -50%)", // Adjust to truly center it
            }} // 80% opacity
          >
            <p style={{ marginBottom: "10px" }}>
              Please Verify your Phone Number to proceed
              <br />
              Code will be sent to{" "}
              {phoneNumber
                ? `*****${phoneNumber.slice(-3)}`
                : "Phone number not available"}
            </p>

            <input
              type="text"
              placeholder="Enter the verification code"
              value={phoneVerificationCode}
              onChange={(e) => setPhoneVerificationCode(e.target.value)}
              style={{ textAlign: "center" }}
            />

            {phoneVerificationError && (
              <span style={{ color: "red" }}>{phoneVerificationError}</span>
            )}

            {/* Button to send verification code */}
            {!verificationCodeSent ? (
              <button
                className="button2"
                onClick={handleResendPhoneVerification}
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Send Verification Code
              </button>
            ) : (
              <button
                className="button2"
                onClick={handlePhoneVerification}
                style={{
                  backgroundColor: "black",
                  color: "white",
                }}
              >
                Verify Phone Number
              </button>
            )}

            <button
              className="button2"
              onClick={() => setShowPhoneVerificationModal(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* Password Update Modal */}
      {/* Password Update Modal */}
      {showPasswordUpdateModal && (
        <div
          className="modal-overlay"
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1000,
          }}
        >
          <div
            className="modal"
            style={{
              backgroundColor: "white",
              width: "600px",
              height: "auto",
              padding: "20px",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <p>
              For your first time login, please update your password to
              continue.
            </p>
            <br />

            {/* New Password Field */}
            <div style={{ position: "relative", width: "400px" }}>
              <input
                type={showPassword1 ? "text" : "password"}
                placeholder="New Password"
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
                style={{ width: "100%", paddingRight: "40px" }}
              />
              <button
                type="button"
                onClick={() => setShowPassword1(!showPassword1)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showPassword1 ? <VisibilityOff /> : <Visibility />}
              </button>
            </div>

            {/* Confirm Password Field */}
            <div
              style={{
                position: "relative",
                width: "400px",
                marginTop: "10px",
              }}
            >
              <input
                type={showPassword2 ? "text" : "password"}
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                style={{ width: "100%", paddingRight: "40px" }}
              />
              <button
                type="button"
                onClick={() => setShowPassword2(!showPassword2)}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                {showPassword2 ? <VisibilityOff /> : <Visibility />}
              </button>
            </div>

            <span
              style={{
                fontSize: "12px",
                marginBottom: "10px",
                color: "gray",
              }}
            >
              "Password must be 8-20 characters long and include at least one
              uppercase letter, one number, and one special character
              (@$!%*?&#)."
            </span>

            {error2 && <span style={{ color: "red" }}>{error2}</span>}
            <button
              className="button1"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? "Updating..." : "Update Password"}
            </button>
            <button
              className="button2"
              onClick={() => setShowPasswordUpdateModal(false)}
            >
              Cancel
            </button>
            {success && <span>Password Updated Successfully</span>}
          </div>
        </div>
      )}
      {/* Recaptcha Container */}
      <div id="recaptcha-container" style={{ display: "none" }}></div>
    </div>
  );
};

export default Login;
