export const userColumns = [
  { field: "id", headerName: "ID", width: 100 },
  // {
  //   field: "username",
  //   label: "Username",
  //   headerName: "Username",
  //   type: "text",
  //   placeholder: "john_doe",
  //   width: 130,
  // },
  {
    field: "displayName",
    headerName: "Full Name",
    label: "Name and Surname",
    type: "text",
    placeholder: "John Doe",
    width: 200,
  },
  {
    field: "email",
    label: "Email",
    headerName: "Email",
    type: "email",
    placeholder: "john_doe@gmail.com",
    width: 250,
  },
  {
    field: "watchId",
    headerName: "Watch Id",
    width: 200,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    label: "Phone Number",
    type: "text",
    placeholder: "+1 234 567 89",
    width: 200,
    valueGetter: (params) => (params.value ? `+${params.value}` : '')
  },
  {
    field: "companyName",
    headerName: "Company Name",
    width: 200,
  },
  {
    field: "locationName",
    headerName: "Location Name",
    width: 200,
  },

  // {
  //   field: "status",
  //   headerName: "Status",
  //   width:100,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
  // {
  //   field: "isActive",
  //   headerName: "Status",
  //   width: 180,
  //   renderCell: (params) => {
  //     return (
  //       <div
  //         className={`cellWithStatus ${
  //           params.row.isActive ? "active" : "inactive"
  //         }`}
  //       >
  //         {params.row.isActive ? "Active" : "Inactive"}
  //       </div>
  //     );
  //   },
  // },
];

export const watchColumns = [
  { field: "id", headerName: "Watch Id", width: 160 },
  // {
  //   field: "watchId",
  //   headerName: "watch Id",
  //   width: 150,
  //   // renderCell: (params) => {
  //   //   return (
  //   //     <div className="cellWithImg">
  //   //       <img className="cellImg" src={params.row.img} alt="avatar" />
  //   //       {params.row.username}
  //   //     </div>
  //   //   );
  //   // },
  // },
  {
    field: "displayName",
    headerName: "Watch Owner",
    width: 180,
  },
  {
    field: "watchDisplayName",
    headerName: "Display Name",
    width: 130,
  },
  {
    field: "phoneNumber",
    headerName: "Phone Number",
    width: 160,
    renderCell: (params) => {
      // Ensure the phone number starts with a '+' sign and format it correctly
      const phoneNumber = params.row.phoneNumber;
      const formattedPhoneNumber =
        phoneNumber && !phoneNumber.startsWith("+")
          ? `+${phoneNumber}`
          : phoneNumber;

      return <span>{formattedPhoneNumber}</span>;
    },
  },
  {
    field: "companyName",
    headerName: "Company",
    width: 190,
  },
  {
    field: "locationName",
    headerName: "Location",
    width: 190,
  },

  // {
  //   field: "groupCode",
  //   headerName: "Group Code",
  //   width: 100,
  // },
  // {
  //   field: "status",
  //   headerName: "Status",
  //   width: 160,
  //   renderCell: (params) => {
  //     return (
  //       <div className={`cellWithStatus ${params.row.status}`}>
  //         {params.row.status}
  //       </div>
  //     );
  //   },
  // },
];

export const relayBoardColumns = [
  {
    field: "did",
    headerName: "Device ID",
    label: "Device ID",
    type: "text",
    width: "190",
    placeholder: "b000349454aa9060",
  },
  {
    field: "companyName",
    headerName: "Company Name",
    type: "text",
    width: "150",
    placeholder: "c93198",
  },
  {
    field: "locationName",
    label: "Location Name",
    headerName: "Location Name",
    type: "text",
    width: "150",
    placeholder: "L00003",
  },
  // {
  //   field: "staticIp",
  //   label: "Static IP",
  //   type: "text",
  //   placeholder: "192.168.5.2",
  // },
  // {
  //   field: "mqttIp",
  //   label: "MQTT IP",
  //   type: "text",
  //   placeholder: "59.154.99.165",
  // },
  // {
  //   field: "relayActiveState",
  //   label: "Relay Active State",
  //   type: "text",
  //   placeholder: "0000",
  // },
  // {
  //   field: "firmwareVersion",
  //   label: "Firmware Version",
  //   type: "text",
  //   placeholder: "1.00",
  // },
  // {
  //   field: "inputAlert",
  //   label: "Input Alert",
  //   type: "text",
  //   placeholder: "GORCGORC",
  // },
];

export const companyColumns = [
  {
    field: "id",
    headerName: "Company ID",
    type: "text",
    placeholder: "C123456",
    width: "160",
  },
  {
    field: "companyName",
    headerName: "Company Name",
    type: "text",
    placeholder: "Acme Corporation",
    width: "200",
  },
  {
    field: "address",
    headerName: "Address",
    type: "text",
    placeholder: "123 Main St, Suite 500",
    width: "250",
  },
  {
    field: "city",
    headerName: "City",
    type: "text",
    placeholder: "Metropolis",
    width: "150",
  },
  {
    field: "state",
    headerName: "State/Province",
    type: "text",
    placeholder: "NY",
    width: "150",
  },
  {
    field: "postalCode",
    headerName: "Postal Code",
    type: "text",
    placeholder: "10001",
    width: "120",
  },
  {
    field: "country",
    headerName: "Country",
    type: "text",
    placeholder: "USA",
    width: "150",
  },
  {
    field: "phone",
    headerName: "Office Number",
    type: "text",
    placeholder: "+1 234 567 8901",
    width: "200",
  },
];

export const locationColumns = [
  {
    field: "id",
    headerName: "Location Id",
    label: "Location ID",
    width: 120,

    type: "text",
    placeholder: "L12345",
  },
  {
    field: "companyName",
    headerName: "Company Name",
    type: "text",
    placeholder: "Headquarters, Branch Office",
    width: 200,
  },
  {
    field: "locationName",
    headerName: "Location Name",
    type: "text",
    placeholder: "Headquarters, Branch Office",
    width: 200,
  },
  {
    field: "address",
    headerName: "Address",
    type: "text",
    placeholder: "123 Main St, Suite 500",
    width: 300,
  },
  {
    field: "city",
    headerName: "City",
    type: "text",
    placeholder: "Metropolis",
    width: 150,
  },
  {
    field: "state",
    headerName: "State/Province",
    type: "text",
    placeholder: "NY",
    width: 150,
  },
  {
    field: "postalCode",
    headerName: "Postal Code",
    type: "text",
    placeholder: "10001",
    width: 150,
  },
  {
    field: "country",
    headerName: "Country",
    type: "text",
    placeholder: "USA",
    width: 150,
  },
];

// export const groupCodeColumn = [
//   {
//     field: "id",
//     label: "Device ID",
//     type: "text",
//     placeholder: "b000349454aa9060",
//   },
//   {
//     field: "companyId",
//     label: "Company ID",
//     type: "text",
//     placeholder: "c93198",
//   },
//   {
//     field: "locationId",
//     label: "Location ID",
//     type: "text",
//     placeholder: "L00003",
//   },
//   {
//     field: "staticIp",
//     label: "Static IP",
//     type: "text",
//     placeholder: "192.168.5.2",
//   },
//   {
//     field: "mqttIp",
//     label: "MQTT IP",
//     type: "text",
//     placeholder: "59.154.99.165",
//   },
//   {
//     field: "relayActiveState",
//     label: "Relay Active State",
//     type: "text",
//     placeholder: "0000",
//   },
//   {
//     field: "firmwareVersion",
//     label: "Firmware Version",
//     type: "text",
//     placeholder: "1.00",
//   },
//   {
//     field: "inputAlert",
//     label: "Input Alert",
//     type: "text",
//     placeholder: "GORCGORC",
//   },
// ];

//temporary data
export const userRows = [
  {
    id: 1,
    username: "Snow",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    status: "active",
    email: "1snow@gmail.com",
    age: 35,
  },
  {
    id: 2,
    username: "Jamie Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "2snow@gmail.com",
    status: "passive",
    age: 42,
  },
  {
    id: 3,
    username: "Lannister",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "3snow@gmail.com",
    status: "pending",
    age: 45,
  },
  {
    id: 4,
    username: "Stark",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "4snow@gmail.com",
    status: "active",
    age: 16,
  },
  {
    id: 5,
    username: "Targaryen",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "5snow@gmail.com",
    status: "passive",
    age: 22,
  },
  {
    id: 6,
    username: "Melisandre",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "6snow@gmail.com",
    status: "active",
    age: 15,
  },
  {
    id: 7,
    username: "Clifford",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "7snow@gmail.com",
    status: "passive",
    age: 44,
  },
  {
    id: 8,
    username: "Frances",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "8snow@gmail.com",
    status: "active",
    age: 36,
  },
  {
    id: 9,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "pending",
    age: 65,
  },
  {
    id: 10,
    username: "Roxie",
    img: "https://images.pexels.com/photos/1820770/pexels-photo-1820770.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500",
    email: "snow@gmail.com",
    status: "active",
    age: 65,
  },
];

export const groupCodeColumn = [
  {
    field: "groupCode",
    label: "Group Code",
    type: "text",
    placeholder: "1",
  },
  {
    field: "location",
    label: "location",
    type: "text",
    placeholder: "Connectified Ph Branch",
  },
  {
    field: "company",
    label: "Company",
    type: "text",
    placeholder: "Connectified",
  },
  {
    field: "Watches",
    label: "watches",
    type: "number",
    placeholder: "62",
  },
  {
    field: "locationID",
    label: "Location ID",
    type: "text",
    placeholder: "L12345",
  },
  {
    field: "companyId",
    label: "Company ID",
    type: "text",
    placeholder: "L12345",
  },
];

export const alarmLogsColumn = [
  {
    field: "timestamp",
    headerName: "Date",
    width: 180,
    valueFormatter: (params) => {
      const timestamp = params.value; // The timestamp value from the data

      // Ensure the timestamp is a valid number (milliseconds)
      if (timestamp) {
        const date = new Date(timestamp); // No need to multiply by 1000 since it's in milliseconds

        // Format the date to dd/mm/yyyy hh:mm:ss AM/PM
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is 0-indexed
        const year = date.getFullYear();

        // Get hours and decide AM or PM
        let hours = date.getHours();
        const ampm = hours >= 12 ? "PM" : "AM"; // Determine AM or PM
        hours = hours % 12; // Convert to 12-hour format
        if (hours === 0) hours = 12; // Handle midnight (0 hours should be 12)

        // Get minutes and seconds
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
      }

      return ""; // If the timestamp is invalid, return an empty string
    },
  },
  { field: "watchId", headerName: "Watch ID", width: 180 },
  { field: "userEmail", headerName: "User Email", width: 200 },
  { field: "alarmCode", headerName: "Alarm Code", width: 150 },
  { field: "companyName", headerName: "Company Name", width: 200 },
  { field: "locationName", headerName: "Location Name", width: 180 },
  {
    field: "audio",
    headerName: "Audio",

    renderCell: (params) =>
      params.row.audioUrl ? (
        <audio controls preload="none">
          <source src={params.row.audioUrl} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
      ) : (
        <span>No audio</span>
      ),
    width: 200,
  },

  { field: "deviceInfo.deviceModel", headerName: "Device Model", width: 150 },
  // { field: "locationId", headerName: "Location ID", width: 150 },

  { field: "locationCode", headerName: "Location Code", width: 150 },
  { field: "latitude", headerName: "Latitude", width: 120 },
  { field: "longitude", headerName: "Longitude", width: 120 },

  { field: "userId", headerName: "User ID", width: 290 },
  { field: "deviceInfo.batteryLevel", headerName: "Battery Level", width: 150 },
  { field: "deviceInfo.browser", headerName: "Browser", width: 150 },
  {
    field: "deviceInfo.connectivityMode",
    headerName: "Connectivity Mode",
    width: 180,
    valueFormatter: (params) => {
      const mode = params.value;
      return mode === "4g" ? "4G" : mode;
    },
  },
];

export const relayBoardAlarmLogColumn = [
  { field: "id", headerName: "ID", width: 180 },
  // { field: "userId", headerName: "User ID", width: 290 },
  { field: "alarmCode", headerName: "Alarm Code", width: 150 },
  { field: "companyName", headerName: "Company Name", width: 200 },
  { field: "deviceInfo.deviceModel", headerName: "Device Model", width: 190 },
  { field: "userEmail", headerName: "User Email", width: 200 },
  { field: "locationCode", headerName: "Location Code", width: 150 },
  { field: "locationId", headerName: "Location ID", width: 150 },
  { field: "locationName", headerName: "Location Name", width: 180 },
  { field: "timestamp", headerName: "Timestamp", width: 180 },

  { field: "latitude", headerName: "Latitude", width: 120 },
  { field: "longitude", headerName: "Longitude", width: 120 },

  { field: "deviceInfo.batteryLevel", headerName: "Battery Level", width: 150 },
  { field: "deviceInfo.browser", headerName: "Browser", width: 150 },
  {
    field: "deviceInfo.connectivityMode",
    headerName: "Connectivity Mode",
    width: 180,
    valueFormatter: (params) => {
      const mode = params.value;
      return mode === "4g" ? "4G" : mode;
    },
  },
];

export const relayBoardLogsColumn = [
  {
    field: "timestamp",
    headerName: "Date",
    width: 180,
    valueFormatter: (params) => {
      const timestamp = params.value;

      if (timestamp) {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, "0");
        const month = String(date.getMonth() + 1).padStart(2, "0");
        const year = date.getFullYear();

        let hours = date.getHours();
        const ampm = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;

        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        return `${day}/${month}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
      }
      return "";
    },
  },
  { field: "deviceId", headerName: "Device Id", width: 190 },
  {
    field: "alert",
    headerName: "Alarm Code",
    width: 150,
    renderCell: (params) => {
      const code = params.value;
      let displayText = "";
      let color = "";

      switch (code) {
        case "R":
          displayText = "Red";
          color = "black";
          break;
        case "O":
          displayText = "Orange";
          color = "black";
          break;
        case "G":
          displayText = "Green";
          color = "black";
          break;
        case "C":
          displayText = "Cancelled";
          color = "black";
          break;
        default:
          displayText = code;
      }

      return (
        <span style={{ color: color, fontWeight: "" }}>{displayText}</span>
      );
    },
  },
  { field: "companyId", headerName: "Company Id", width: 200 },
  { field: "locationId", headerName: "Location Id", width: 200 },
  {
    field: "type",
    headerName: "Device Type",
    width: 150,
    valueFormatter: (params) => {
      // Check the value of the type field and display "Relay Board" if the value is "B"
      if (params.value === "B") {
        return "Relay Board";
      }
      return params.value; // Return the original value if it's not "B"
    },
  },
];
