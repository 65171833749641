import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { alarmLogsColumn } from "../../datatablesource";
import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { database } from "../../firebase";
import {
  IconButton,
  Menu,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  MenuItem as MuiMenuItem,
  TextField,
  Button,
  Modal,
} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const SingleWatchLogDataTable = () => {
  const [data, setData] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [locations, setLocations] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { watchId } = useParams();
  const [loginLogs, setLoginLogs] = useState([]);

  //modal

  const [openLogModal, setOpenLogModal] = useState(false);
  const handleLogModalOpen = () => setOpenLogModal(true);
  const handleLogModalClose = () => setOpenLogModal(false);

  // Filter states
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const [locationIdFilter, setLocationIdFilter] = useState("");
  const [alarmCodeFilter, setAlarmCodeFilter] = useState("");
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const [watchIdFilter, setWatchIdFilter] = useState("");
  const [userEmailFilter, setUserEmailFilter] = useState("");
  const [deviceTypeFilter, setDeviceTypeFilter] = useState("");

  const handleFilterChange = (type, value) => {
    console.log(`Changing filter for ${type}: ${value}`);
    if (type === "watchId") setWatchIdFilter(value);
    else if (type === "userEmail") setUserEmailFilter(value);
    else if (type === "deviceType") setDeviceTypeFilter(value);
  };

  // Function to map alarm codes to their descriptions
  const getAlarmStatus = (code) => {
    switch (code) {
      case "C":
        return "Cancelled";
      case "G":
        return "Green";
      case "R":
        return "Red";
      case "O":
        return "Orange";
      default:
        return "Unknown";
    }
  };

  useEffect(() => {
    if (!watchId) return;

    const logsRef = ref(database, `/Breadcrumbs/${watchId}/logs/alarm`);
    const loginLogsRef = ref(
      database,
      `/Breadcrumbs/${watchId}/logs/onlineStatus`
    );

    const unsubLogs = onValue(logsRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const logData = childSnapshot.val();
        list.push({
          id: childSnapshot.key,
          companyName: logData.companyName,
          alarmCode: logData.alarmCode,
          timestamp: logData.timestamp,
        });
      });
      setData(list);
    });

    const unsubLoginLogs = onValue(loginLogsRef, (snapshot) => {
      let logList = [];
      snapshot.forEach((childSnapshot) => {
        const logData = childSnapshot.val();
        logList.push({
          id: childSnapshot.key,
          status: logData.status,
          timestamp: dayjs(logData.timestamp)
            .utc()
            .format("DD/MM/YYYY HH:mm:ss A"),
        });
      });
      setLoginLogs(logList);
    });

    return () => {
      unsubLogs();
      unsubLoginLogs();
    };
  }, [watchId]);

  useEffect(() => {
    if (!watchId) return;

    const logsRef = ref(database, `/Breadcrumbs/${watchId}/logs/alarm`);
    const companiesRef = ref(database, "/Companies");
    const locationsRef = ref(database, "/locations");

    const unsubLogs = onValue(logsRef, (snapshot) => {
      let list = [];
      snapshot.forEach((childSnapshot) => {
        const logData = childSnapshot.val();
        const flattenedData = {
          id: childSnapshot.key,
          companyName: logData.companyName,
          alarmCode: getAlarmStatus(logData.alarmCode),
          "deviceInfo.batteryLevel": logData.deviceInfo?.batteryLevel || "N/A",
          "deviceInfo.browser": logData.deviceInfo?.browser || "N/A",
          "deviceInfo.connectivityMode":
            logData.deviceInfo?.connectivityMode || "N/A",
          "deviceInfo.deviceModel": logData.deviceInfo?.deviceModel || "N/A",
          latitude: logData.latitude,
          longitude: logData.longitude,
          locationCode: logData.locationCode,
          locationId: logData.locationId,
          locationLatitude: logData.locationLatitude,
          locationLongitude: logData.locationLongitude,
          locationName: logData.locationName,
          timestamp: logData.timestamp,
          userEmail: logData.userEmail,
          userId: logData.userId,
          watchId: logData.watchId || watchId,
          audioUrl: logData.audio || "",
        };

        // Apply filters
        const logDate = dayjs(logData.timestamp);

        if (
          (companyNameFilter && logData.companyName !== companyNameFilter) ||
          (locationIdFilter && logData.locationId !== locationIdFilter) ||
          (alarmCodeFilter && logData.alarmCode !== alarmCodeFilter) ||
          (startDateFilter && logDate.isBefore(dayjs(startDateFilter))) ||
          (endDateFilter && logDate.isAfter(dayjs(endDateFilter)))
        ) {
          return;
        }

        list.push(flattenedData);
      });

      list.sort((a, b) => b.timestamp - a.timestamp);
      setData(list);
    });

    const unsubCompanies = onValue(companiesRef, (snapshot) => {
      let companyList = [];
      snapshot.forEach((childSnapshot) => {
        const company = childSnapshot.val();
        companyList.push({ id: childSnapshot.key, name: company.companyName });
      });
      setCompanies(companyList);
    });

    const unsubLocations = onValue(locationsRef, (snapshot) => {
      let locationList = [];
      snapshot.forEach((childSnapshot) => {
        const location = childSnapshot.val();
        locationList.push({
          id: childSnapshot.key,
          name: location.locationName,
        });
      });
      setLocations(locationList);
    });

    return () => {
      unsubLogs();
      unsubCompanies();
      unsubLocations();
    };
  }, [
    watchId,
    companyNameFilter,
    locationIdFilter,
    alarmCodeFilter,
    startDateFilter,
    endDateFilter,
  ]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    doc.text("Logs Report", 20, 10);
    doc.setFontSize(12);

    const yStart = 20;
    const lineHeight = 10;
    const maxLinesPerPage = Math.floor(
      (doc.internal.pageSize.height - yStart) / lineHeight
    );

    let y = yStart;

    data.forEach((log, index) => {
      if (y + 4 * lineHeight > doc.internal.pageSize.height) {
        doc.addPage();
        y = yStart;
      }

      doc.setFontSize(10);
      doc.text(`Log ID: ${log.id}`, 20, y);
      y += lineHeight;
      doc.text(`Company Name: ${log.companyName}`, 20, y);
      y += lineHeight;
      doc.text(`Alarm Code: ${log.alarmCode}`, 20, y);
      y += lineHeight;
      doc.text(
        `Device Battery Level: ${log["deviceInfo.batteryLevel"]}`,
        20,
        y
      );
      y += lineHeight;
      doc.text(`Device Browser: ${log["deviceInfo.browser"]}`, 20, y);
      y += lineHeight;
      doc.text(
        `Device Connectivity Mode: ${log["deviceInfo.connectivityMode"]}`,
        20,
        y
      );
      y += lineHeight;
      doc.text(`Device Model: ${log["deviceInfo.deviceModel"]}`, 20, y);
      y += lineHeight;
      doc.text(`Latitude: ${log.latitude}`, 20, y);
      y += lineHeight;
      doc.text(`Longitude: ${log.longitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Code: ${log.locationCode}`, 20, y);
      y += lineHeight;
      doc.text(`Location ID: ${log.locationId}`, 20, y);
      y += lineHeight;
      doc.text(`Location Latitude: ${log.locationLatitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Longitude: ${log.locationLongitude}`, 20, y);
      y += lineHeight;
      doc.text(`Location Name: ${log.locationName}`, 20, y);
      y += lineHeight;
      doc.text(`Timestamp: ${log.timestamp}`, 20, y);
      y += lineHeight;
      doc.text(`User Email: ${log.userEmail}`, 20, y);
      y += lineHeight;
      doc.text(`User ID: ${log.userId}`, 20, y);
      y += lineHeight;
      doc.text(`Watch ID: ${log.watchId}`, 20, y);
      y += lineHeight * 2; // Add extra space after each log entry
    });

    doc.save("logs_report.pdf");
    handleMenuClose();
  };

  const clearFilters = () => {
    setCompanyNameFilter("");
    setLocationIdFilter("");
    setStartDateFilter(null);
    setEndDateFilter(null);
    setAlarmCodeFilter("");
    setWatchIdFilter("");
    setUserEmailFilter("");
    setDeviceTypeFilter("");
  };

  const downloadExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Logs");
    XLSX.writeFile(wb, "logs_report.xlsx");
    handleMenuClose();
  };

  const downloadPDFLogin = (logs) => {
    const doc = new jsPDF();
    doc.text("Watch State Logs", 20, 10);
    let y = 20;

    logs.forEach((log) => {
      doc.setFontSize(10);
      doc.text(`Log Id: ${log.id}`, 20, y);
      doc.text(`Status: ${log.status}`, 20, y + 10);
      doc.text(`Timestamp: ${log.timestamp}`, 20, y + 20);
      y += 30;
    });

    doc.save("Watch_State_Logs.pdf");
    handleMenuClose();
  };

  const downloadExcelLogin = (logs) => {
    const ws = XLSX.utils.json_to_sheet(logs);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Login Logs");
    XLSX.writeFile(wb, "Watch_State_Logs.xlsx");
    handleMenuClose();
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <span
          style={{
            color: params.value === "online" ? "green" : "red",
            fontWeight: "500",
          }}
        >
          {params.value}
        </span>
      ),
    },
    { field: "timestamp", headerName: "Date", flex: 1 },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        <div>
          Data Log
          <Button
            variant="contained"
            onClick={handleLogModalOpen}
            style={{
              marginLeft: "30px",
            }}
          >
            View Watch State Logs
          </Button>
        </div>
        <IconButton
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleMenuOpen}
          color="primary"
        >
          <DownloadIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={downloadPDF}>Download as PDF</MenuItem>
          <MenuItem onClick={downloadExcel}>Download as Excel</MenuItem>
        </Menu>
      </div>

      <Modal open={openLogModal} onClose={handleLogModalClose}>
        <div
          style={{
            padding: "20px",
            backgroundColor: "white",
            borderRadius: "8px",
            maxHeight: "80vh",
            overflowY: "auto",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "400px", // optional: specify a width if needed
          }}
        >
          <div style={{ position: "relative" }}>
            <h2 style={{ fontWeight: "400", marginBottom: "10px" }}>
              Watch State Logs
            </h2>

            {/* Close button */}
            <Button
              onClick={handleLogModalClose}
              style={{
                position: "absolute",
                top: "0px",
                right: "10px",
                padding: "0px",
                minWidth: "unset",
                color:"gray"
              }}
            >
                <span style={{ fontSize: "15px", fontWeight: "bold", color: "#333" }}>×</span>
            </Button>

            <div>
              {loginLogs.length > 0 ? (
                <div>
                  <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                      rows={loginLogs}
                      columns={columns}
                      pageSize={20}
                      rowsPerPageOptions={[20]}
                      disableSelectionOnClick
                      getRowId={(row) => row.id}
                    />
                  </div>
                  <Button
                    variant="contained"
                    onClick={() => downloadPDFLogin(loginLogs)}
                    style={{ marginTop: "10px" }}
                  >
                    Download as PDF
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => downloadExcelLogin(loginLogs)}
                    style={{ marginLeft: "10px", marginTop: "10px" }}
                  >
                    Download as Excel
                  </Button>
                </div>
              ) : (
                <p>No login logs available.</p>
              )}
            </div>
          </div>
        </div>
      </Modal>

      <div
        className="filters"
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "20px",
          alignItems: "center",
        }}
      >
        <FormControl variant="outlined" style={{ minWidth: 200 }}>
          <InputLabel>Alarm Code</InputLabel>
          <Select
            value={alarmCodeFilter}
            onChange={(e) => setAlarmCodeFilter(e.target.value)}
            label="Alarm Code"
          >
            <MenuItem value="">All</MenuItem>
            <MenuItem value="C">Cancelled</MenuItem>
            <MenuItem value="G">Green</MenuItem>
            <MenuItem value="R">Red</MenuItem>
            <MenuItem value="O">Orange</MenuItem>
          </Select>
        </FormControl>

        <TextField
          label="Start Date"
          type="date"
          value={
            startDateFilter ? dayjs(startDateFilter).format("YYYY-MM-DD") : ""
          }
          onChange={(e) => setStartDateFilter(e.target.value)}
          variant="outlined"
          style={{ minWidth: 200 }}
          InputLabelProps={{ shrink: true }}
        />

        <TextField
          label="End Date"
          type="date"
          value={endDateFilter ? dayjs(endDateFilter).format("YYYY-MM-DD") : ""}
          onChange={(e) => setEndDateFilter(e.target.value)}
          variant="outlined"
          style={{ minWidth: 200 }}
          InputLabelProps={{ shrink: true }}
        />

        {/* Filters for Watch ID, User Email, and Device Type - Below Existing Filters */}
        {/* <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <TextField
            label="Watch ID"
            fullWidth
            value={watchIdFilter}
            onChange={(e) => handleFilterChange("watchId", e.target.value)}
          />
        </FormControl> */}

        <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <TextField
            label="User Email"
            fullWidth
            value={userEmailFilter}
            onChange={(e) => handleFilterChange("userEmail", e.target.value)}
          />
        </FormControl>

        <FormControl variant="outlined" fullWidth style={{ flex: 1 }}>
          <TextField
            label="Device Type"
            fullWidth
            value={deviceTypeFilter}
            onChange={(e) => handleFilterChange("deviceType", e.target.value)}
          />
        </FormControl>

        <Button onClick={clearFilters} style={{ alignSelf: "center" }}>
          Clear Filters
        </Button>
      </div>

      <DataGrid
        rows={data}
        columns={alarmLogsColumn}
        pageSize={10}
        rowsPerPageOptions={[10]}
        sortModel={[
          {
            field: "timestamp", // Field name that you want to sort
            sort: "desc", // Sort direction (descending)
          },
        ]}
      />
    </div>
  );
};

export default SingleWatchLogDataTable;
