import "./featured.scss";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useEffect, useState, useContext } from "react";
import { ref, onValue, off, update } from "firebase/database";
import { database } from "../../firebase";
import { DataGrid } from "@mui/x-data-grid";
import { AuthContext } from "../../context/AuthContext";

const Featured = ({ onSelectWatch }) => {
  // Add callback prop
  const [todayCount, setTodayCount] = useState(0);
  const [weekCount, setWeekCount] = useState(0);
  const [monthCount, setMonthCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentAlarms, setCurrentAlarms] = useState([]);
  const { currentUser } = useContext(AuthContext);

  // const [timerInterval, setTimerInterval] = useState(
  //   parseInt(localStorage.getItem("geofenceTimer")) || 30
  // );

  // const handleTimerChange = (e) => {
  //   const value = parseInt(e.target.value);
  //   setTimerInterval(value);
  //   localStorage.setItem("geofenceTimer", value);
  // };

  useEffect(() => {
    const logsRef = ref(database, "logs/alarm");
    const watchesRef = ref(database, "Watches");

    const fetchAlarms = () => {
      onValue(logsRef, (snapshot) => {
        if (snapshot.exists()) {
          const alarms = snapshot.val();
          const now = Date.now();
          const todayStart = new Date(now).setHours(0, 0, 0, 0);
          const weekStart = new Date(now - 7 * 24 * 60 * 60 * 1000).setHours(
            0,
            0,
            0,
            0
          );
          const monthStart = new Date(now - 30 * 24 * 60 * 60 * 1000).setHours(
            0,
            0,
            0,
            0
          );

          let todayAlarms = 0;
          let weekAlarms = 0;
          let monthAlarms = 0;
          let totalAlarms = 0;

          Object.values(alarms).forEach((alarm) => {
            const alarmCode = alarm.alarmCode;
            const timestamp = alarm.timestamp;

            if (alarmCode !== "C") {
              totalAlarms++;

              if (timestamp >= todayStart) {
                todayAlarms++;
              }
              if (timestamp >= weekStart) {
                weekAlarms++;
              }
              if (timestamp >= monthStart) {
                monthAlarms++;
              }
            }
          });

          setTodayCount(todayAlarms);
          setWeekCount(weekAlarms);
          setMonthCount(monthAlarms);
          setTotalCount(totalAlarms);
        }
      });
    };

    const fetchWatches = () => {
      if (currentUser && [1, 2, 3].includes(currentUser.role)) {
        const companyId = currentUser.role === 1 ? null : currentUser.companyId;

        onValue(watchesRef, (snapshot) => {
          if (snapshot.exists()) {
            const watches = snapshot.val();
            const now = Date.now();

            const filteredWatches = Object.entries(watches).filter(
              ([key, watch]) => {
                // Filter by companyId if the role is 2 or 3
                if (companyId && watch.companyId !== companyId) {
                  return false; // Skip watches with a different companyId
                }
                return (
                  watch.alarmCode !== "C" ||
                  (watch.alarmCode === "C" &&
                    (watch.geoFenceAlarm === "O" ||
                      watch.geoFenceAlarm === "I"))
                );
              }
            );

            const mappedAlarms = filteredWatches.map(([key, watch]) => {
              // const geofenceTimerKey = `geofenceTimer_${key}`;
              // const timerStart = localStorage.getItem(geofenceTimerKey);

              // if (
              //   (watch.geoFenceAlarm === "O" || watch.geoFenceAlarm === "I") &&
              //   !timerStart
              // ) {
              //   localStorage.setItem(geofenceTimerKey, now);
              // }

              // if (
              //   (watch.geoFenceAlarm === "O" || watch.geoFenceAlarm === "I") &&
              //   timerStart
              // ) {
              //   const elapsedMinutes = (now - timerStart) / (60 * 1000);
              //   if (elapsedMinutes >= timerInterval) {
              //     watch.geoFenceAlarm = ""; // Reset geofence alarm
              //     localStorage.removeItem(geofenceTimerKey);

              //     // Update Firebase
              //     const watchRef = ref(database, `Watches/${key}`);
              //     update(watchRef, {
              //       geoFenceAlarm: "", // Update geoFenceAlarm to empty
              //     }).catch((error) => {
              //       console.error("Error updating geoFenceAlarm:", error);
              //     });
              //   }
              // }

              return {
                id: key,
                watchCode: watch.alarmCode,
                watchOwner: watch.watchDisplayName
                  ? watch.watchDisplayName
                  : watch.displayName || "Unknown Owner",
                watchLocation: watch.locationName || "Unknown Location",
                watchFenceCode: watch.geoFenceAlarm || "No Geofence",
              };
            });

            setCurrentAlarms(mappedAlarms);
          }
        });
      }
    };

    if (currentUser && [1, 2, 3].includes(currentUser.role)) {
      fetchAlarms();
      fetchWatches();
    } else {
      // If the role is not 1, 2, or 3, don't run the function
      console.log(
        "Unauthorized access: User does not have the appropriate role."
      );
    }

    fetchAlarms();
    fetchWatches();

    // Clean up listeners on unmount
    return () => {
      off(logsRef);
      off(watchesRef);
    };
  }, []);

  const handleRowClick = (row) => {
    onSelectWatch(row.id); // Pass the selected watch ID to parent
  };

  // Define columns for the DataGrid
  const columns = [
    {
      field: "watchCode",
      headerName: "Alarm Code",
      width: 115,
      renderCell: (params) => {
        const { watchCode, watchFenceCode } = params.row;
        let displayText;

        switch (watchCode) {
          case "G":
            displayText = <span className="alarm-green-text">Green</span>;
            break;
          case "R":
            displayText = <span className="alarm-red-text">Red</span>;
            break;
          case "O":
            displayText = <span className="alarm-orange-text">Orange</span>;
            break;
          case "C": // Special case for Idle
            displayText = <span className="alarm-idle-text">Idle</span>;
            break;
          default:
            displayText = <span>{watchCode}</span>;
        }

        let geoFenceText = ""; // Initialize as empty
        if (watchFenceCode === "F") {
          geoFenceText = "/ Fence";
        } else if (watchFenceCode === "I") {
          geoFenceText = "/ Inbound";
        } else if (watchFenceCode === "O") {
          geoFenceText = "/ Outbound";
        }

        return (
          <span>
            {displayText}
            {geoFenceText && (
              <span style={{ marginLeft: 5 }}>{geoFenceText}</span>
            )}
          </span>
        );
      },
      cellClassName: (params) => {
        const { watchCode, watchFenceCode } = params.row;

        // Special case for Inbound (I) or Outbound (O)
        if (watchFenceCode === "I" || watchFenceCode === "O") {
          if (watchCode === "G" || watchCode === "O") {
            return "alarm-yellow";
          } else if (watchCode === "R") {
            return "alarm-red-yellow-border";
          } else if (watchCode === "C") {
            // Idle case
            return "alarm-yellow";
          }
        }

        // Default watchCode color logic
        switch (watchCode) {
          case "G":
            return "alarm-green";
          case "R":
            return "alarm-red";
          case "O":
            return "alarm-orange";
          default:
            return "";
        }
      },
    },
    { field: "watchOwner", headerName: "User/Display Name", width: 160 },
    { field: "watchLocation", headerName: "Location", width: 150 },
  ];

  return (
    <div className="featured">
      <div className="top">
        <h1 className="title">Total Alarms</h1>
        <MoreVertIcon fontSize="small" />
      </div>
      <div className="bottom">
        {/* <div className="featuredChart">
          <CircularProgressbar
            value={totalCount > 0 ? (todayCount / totalCount) * 100 : 0}
            text={${todayCount} / ${totalCount}}
            strokeWidth={5}
          />
        </div> */}
        <p className="title">Alarms Triggered Today</p>
        <p className="amount">{todayCount}</p>
        <p className="desc">Compared to previous periods</p>
        <div className="summary">
          <div className="item">
            <div className="itemTitle">Today</div>
            <div className="itemResult negative">
              <KeyboardArrowDownIcon fontSize="small" />
              <div className="resultAmount">{todayCount}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Week</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">{weekCount}</div>
            </div>
          </div>
          <div className="item">
            <div className="itemTitle">Last Month</div>
            <div className="itemResult positive">
              <KeyboardArrowUpOutlinedIcon fontSize="small" />
              <div className="resultAmount">{monthCount}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        className="timer-dropdown"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          margin: "10px 0",
        }}
      >
        <label
          htmlFor="timerSelect"
          style={{ fontWeight: "300", fontSize: "14px", color: "#333" }}
        >
          Timer to reset geofence alarms:
        </label>
        <select
          id="timerSelect"
          value={timerInterval}
          onChange={handleTimerChange}
          style={{
            padding: "5px 10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
            backgroundColor: "#f9f9f9",
            fontSize: "14px",
            color: "#333",
          }}
        >
          <option value={1}>1 min</option>
          <option value={5}>5 mins</option>
          <option value={15}>15 mins</option>
          <option value={30}>30 mins</option>
          <option value={60}>60 mins</option>
        </select>
      </div> */}

      <div
        className="currentAlarmsTable"
        style={{ height: 300, width: "100%" }}
      >
        <p className="title" style={{ color: "black", textAlign: "center" }}>
          Current Alarms
        </p>

        <DataGrid
          rows={currentAlarms} // Pass the data
          columns={columns} // Pass the column configuration
          pageSize={10} // Show 5 rows per page
          rowsPerPageOptions={[5, 10, 15]} // Allow pagination options
          getRowId={(row) => row.id} // Specify custom id field
          onRowClick={(params) => handleRowClick(params.row)} // Handle row click
          sx={{
            fontSize: 12, // Set text size smaller
            "& .MuiDataGrid-columnHeaders": {
              fontSize: 14, // Adjust column header font size
            },
            "& .MuiDataGrid-row": {
              fontSize: 12, // Adjust row text size
            },
            "& .MuiDataGrid-cell": {
              padding: "8px", // Adjust cell padding
            },
          }}
        />
      </div>
    </div>
  );
};

export default Featured;
