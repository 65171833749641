import React, { useState, useEffect, useCallback } from "react";
import { database } from "../../firebase";
import { ref, get, push, update, set } from "firebase/database";
import "./../Modals/Modal.css";

const SubscribeToPlanModal = ({
  watchId,
  onClose,
  watchCompanyId,
  watchLocationId,
  watchPlan,
  watchIsSubscribed,
  fetchWatchDetail2,
}) => {
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [loading, setLoading] = useState(false);
  const [planSelected, setPlanSelected] = useState("");
  const [isGeoFenceIncluded, setIsGeoFenceIncluded] = useState(false); // Track geofencing status

  // Fetch the available plans from the database
  useEffect(() => {
    const fetchPlans = async () => {
      setLoading(true);
      try {
        const plansRef = ref(database, "Billing/Plans");
        const snapshot = await get(plansRef);
        if (snapshot.exists()) {
          const plansList = Object.keys(snapshot.val()).map((key) => ({
            id: key,
            ...snapshot.val()[key],
          }));
          setPlans(plansList);
        } else {
          console.log("No plans found");
        }
      } catch (error) {
        console.error("Error fetching plans: ", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlans();
  }, []);

  // Fetch the current plan and geofencing status for the watch
  useEffect(() => {
    const fetchWatchData = async () => {
      const watchRef = ref(database, `Watches/${watchId}`);
      const snapshot = await get(watchRef);
      if (snapshot.exists()) {
        const watchData = snapshot.val();
        setPlanSelected(watchData.planSelected || "");
        setIsGeoFenceIncluded(watchData.isGeoFenceIncluded || false);
      }
    };

    fetchWatchData();
  }, [watchId]);

  const handleSelectPlan = (plan) => {
    setSelectedPlan(plan);
  };

  const handleSubscribe = async () => {
    if (selectedPlan) {
      const billingLogsRef = ref(
        database,
        `Billing/billingLogs/${watchCompanyId}`
      );

      const newLog = {
        watchId,
        subscriptionPlan: selectedPlan.subscriptionPlan,
        price: selectedPlan.price,
        description: selectedPlan.description,
        timestamp: new Date().toISOString(),
      };

      try {
        setLoading(true);
        await push(billingLogsRef, newLog);

        const watchRef = ref(database, `Watches/${watchId}`);

        const subscribedDate = new Date().toISOString();
        const subscriptionEndDate = new Date();
        subscriptionEndDate.setMonth(subscriptionEndDate.getMonth() + 1);

        // Step 1: Get the existing data for geoFencing
        const watchSnapshot = await get(watchRef);
        const existingWatchData = watchSnapshot.val();

        // Step 2: Prepare the updated geoFencing object, preserving geofences
        const updatedGeoFencing = {
          enabled: false, // Update the enabled field
          geofences: existingWatchData.geoFencing?.geofences || [], // Preserve the geofences array
        };

        // Step 3: Prepare the updated watch data
        const updatedWatchData = {
          planSelected: selectedPlan.subscriptionPlan,
          isSubscribed: true,
          bill: selectedPlan.price,
          subscribedDate: subscribedDate,
          subscriptionEndDate: subscriptionEndDate.toISOString(),
          isGeoFenceIncluded: false,
          geoFencing: updatedGeoFencing, // Apply the merged geoFencing object
        };

        // Step 4: Update the watch data in the database
        await update(watchRef, updatedWatchData);
        onClose();

        // Call the passed fetchWatchDetail2 function after subscription
        fetchWatchDetail2();
        alert(`Your Plan has been updated to ${selectedPlan.subscriptionPlan}`);
      } catch (error) {
        console.error("Error adding billing log or updating watch: ", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCancelSubscription = async () => {
    const confirmCancel = window.confirm(
      "Are you sure you want to cancel the subscription?"
    );
    if (!confirmCancel) return;

    try {
      const watchRef = ref(database, `Watches/${watchId}`);

      // Step 1: Get the existing data for the watch
      const watchSnapshot = await get(watchRef);
      const existingWatchData = watchSnapshot.val();

      if (!existingWatchData) {
        console.error("Watch data not found");
        return;
      }

      // Step 2: Prepare the updated data for the geoFencing field
      const updatedGeoFencing = {
        enabled: false, // Disable geofencing
        ...(existingWatchData?.geoFencing?.geofences && {
          geofences: existingWatchData.geoFencing.geofences,
        }), // Preserve geofences if they exist
      };

      // Step 3: Prepare the updated watch data
      const updatedWatchData = {
        isSubscribed: false,
        planSelected: null,
        bill: null,
        subscribedDate: null,
        subscriptionEndDate: null,
        isGeoFenceIncluded: false,
        geoFencing: updatedGeoFencing, // Apply updated geofencing data
      };

      // Step 4: Update the watch data in the database
      await update(watchRef, updatedWatchData);

      console.log(`Cancelled subscription for Watch ${watchId}`);
      alert(`Cancelled subscription for Watch ${watchId}`);

      // Step 5: Log cancellation in Billing Logs
      const billingLogsRef = ref(
        database,
        `Billing/billingLogs/${watchCompanyId}`
      );
      const newLogRef = push(billingLogsRef);

      const cancellationLog = {
        watchId,
        subscriptionPlan: `Subscription Cancellation - ${
          existingWatchData.planSelected || "N/A"
        }`,
        price: existingWatchData.bill || "0",
        description: `Subscription for ${
          existingWatchData.planSelected || "N/A"
        } has been cancelled.`,
        timestamp: new Date().toISOString(),
      };

      await set(newLogRef, cancellationLog);
      console.log("Cancellation logged in Billing Logs");

      onClose();

      // Call the passed fetchWatchDetail2 function after cancelling the subscription
      fetchWatchDetail2();
    } catch (error) {
      console.error("Error canceling subscription: ", error);
     
      fetchWatchDetail2();
    }
  };

  // Add functionality for including geofencing
  const handleIncludeGeofencing = async () => {
    if (planSelected === "") {
      alert("Please subscribe to a plan first.");
      return;
    }

    const confirmGeofencing = window.confirm(
      "Adding geofencing will cost an additional $50 to your current plan. Do you want to include geofencing?"
    );
    if (confirmGeofencing) {
      try {
        const watchRef = ref(database, `Watches/${watchId}`);
        const watchSnapshot = await get(watchRef);
        const existingWatchData = watchSnapshot.val();

        // Ensure the bill is treated as a number (parse it as a float or number)
        const currentBill = parseFloat(existingWatchData.bill) || 0; // Default to 0 if bill is undefined or NaN

        // Create a new billing log for adding geofencing
        const billingLogsRef = ref(
          database,
          `Billing/billingLogs/${watchCompanyId}`
        );

        const newLog = {
          watchId,
          subscriptionPlan: `Added Geofence to ${planSelected}`, // Change subscriptionPlan to reflect geofencing addition
          price: 50, // The cost for adding geofencing
          description: `Added geofencing to ${planSelected} plan`, // Description of the action
          timestamp: new Date().toISOString(),
        };

        // Add the new log
        await push(billingLogsRef, newLog);

        // Update the watch data with geofencing included
        const updatedWatchData = {
          ...existingWatchData,
          isGeoFenceIncluded: true,
          bill: currentBill + 50, // Add $50 to the current bill
          geoFencing: {
            ...existingWatchData.geoFencing,
            enabled: true, // Enable geofencing
          },
        };

        await update(watchRef, updatedWatchData);
        alert("Geofencing has been added to your plan.");
        setIsGeoFenceIncluded(true); // Update local state
        fetchWatchDetail2(); // Fetch updated watch details
      } catch (error) {
        console.error("Error updating geofencing: ", error);
        alert("Error updating geofencing")
      }
    }
  };

  // Add functionality for canceling geofencing
  const handleCancelGeofencing = async () => {
    const confirmCancelGeofencing = window.confirm(
      "Are you sure you want to cancel geofencing? This will remove geofencing from your current plan."
    );
    if (!confirmCancelGeofencing) return;

    try {
      const watchRef = ref(database, `Watches/${watchId}`);
      const watchSnapshot = await get(watchRef);
      const existingWatchData = watchSnapshot.val();

      // Ensure the bill is treated as a number
      const currentBill = parseFloat(existingWatchData.bill) || 0; // Default to 0 if undefined

      const updatedWatchData = {
        ...existingWatchData,
        isGeoFenceIncluded: false,
        bill: currentBill - 50, // Subtract $50 from the current bill
        geoFencing: {
          ...existingWatchData.geoFencing,
          enabled: false, // Disable geofencing
        },
      };

      await update(watchRef, updatedWatchData);
      alert("Geofencing has been removed from your plan.");
      setIsGeoFenceIncluded(false); // Update local state
      fetchWatchDetail2(); // Fetch updated watch details

      // Log geofencing cancellation
      const billingLogsRef = ref(
        database,
        `Billing/billingLogs/${watchCompanyId}`
      );
      const cancellationLog = {
        watchId,
        subscriptionPlan: `Geofencing Removal - ${
          existingWatchData.planSelected || "N/A"
        }`,
        price: "50",
        description: `Geofencing has been removed from the subscription for ${
          existingWatchData.planSelected || "N/A"
        }.`,
        timestamp: new Date().toISOString(),
      };

      await push(billingLogsRef, cancellationLog);
      console.log("Geofencing cancellation logged successfully.");
    } catch (error) {
      console.error("Error canceling geofencing: ", error);
      alert("Error canceling geofencing")
    }
  };

  // Filter out the selected plan from the list of available plans
  const filteredPlans = plans.filter(
    (plan) => plan.subscriptionPlan !== watchPlan
  );

  return (
    <div className="modal" style={{ zIndex: "999999" }}>
      <div
        className="modalContent"
        style={{
          width: "500px",
          zoom: "0.9",
        }}
      >
        <h2 style={{ fontWeight: "300", fontSize: "20px" }}>
          {watchIsSubscribed
            ? `Update Watch ${watchId}`
            : `Subscribe to a Plan for Watch ${watchId}`}
        </h2>

        {filteredPlans.length > 0 ? (
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            <h3 style={{ fontWeight: "300", fontSize: "16px" }}>
              Select a Plan:
            </h3>
            <ul>
              {filteredPlans.map((plan) => (
                <li
                  key={plan.id}
                  style={{
                    padding: "10px",
                    margin: "5px 0",
                    backgroundColor:
                      selectedPlan?.id === plan.id ? "#d3eafc" : "white",
                    cursor: "pointer",
                  }}
                  onClick={() => handleSelectPlan(plan)}
                >
                  <h4>{plan.subscriptionPlan}</h4>
                  <p>Price: ${plan.price}</p>
                  <p>{plan.description}</p>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <p>No Available Plans</p>
        )}

        <button
          onClick={handleSubscribe}
          disabled={!selectedPlan || loading}
          style={{
            marginRight: "10px",
          }}
        >
          {loading
            ? "Processing..."
            : watchIsSubscribed
            ? "Update Watch"
            : "Subscribe to Plan"}
        </button>

        {/* Cancel subscription button */}
        {watchIsSubscribed && !loading && (
          <button
            onClick={handleCancelSubscription}
            style={{
              color: "red",
            }}
          >
            Cancel Subscription
          </button>
        )}

        {/* Geofencing button */}
        {!isGeoFenceIncluded ? (
          <button
            onClick={handleIncludeGeofencing}
            disabled={planSelected === "" || loading}
            style={{
              marginRight: "10px",
            }} // Disable if no plan is selected
          >
            Include Geofencing in Your Current Plan (+$50)
          </button>
        ) : (
          <button
            disabled
            style={{
              marginRight: "10px",
            }}
          >
            Geofencing Active
          </button>
        )}

        {/* Cancel Geofencing button */}
        {isGeoFenceIncluded && (
          <button
            onClick={handleCancelGeofencing}
            style={{
              color: "red",
            }}
          >
            Cancel Geofencing
          </button>
        )}

        <button
          onClick={onClose}
          style={{
            marginLeft: "10px",
            zoom: "0.8",
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default SubscribeToPlanModal;
