import "./chart.scss";
import { useEffect, useState,useContext, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import {
  ref,
  query,
  orderByChild,
  equalTo,
  onValue,
  push,
} from "firebase/database";
import { database } from "../../firebase";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import mapIcon from "../../Images/mapIcon.png";
import redWatchIconUrl from "../../Images/1.png";
import orangeWatchIconUrl from "../../Images/2.png";
import greenIconUrl from "../../Images/3.png";
import cancelledIconUrl from "../../Images/4.png";
import { AuthContext } from "../../context/AuthContext";

const UpdateMapBounds = ({ watches }) => {
  const map = useMap();
  const hasFocusedInitially = useRef(false);

  useEffect(() => {
    if (watches.length > 0 && !hasFocusedInitially.current) {
      const bounds = watches.map((watch) => [
        watch.watchLatitude,
        watch.watchLongitude,
      ]);
      map.fitBounds(bounds); // Adjust the map view to fit all markers
      hasFocusedInitially.current = true; // Mark as focused
    }
  }, [watches, map]);

  return null;
};

const CompanyWatchesMap = ({ companyId }) => {
  const [data, setData] = useState([]);
  const [locationData, setLocationData] = useState({});
  const [companyData, setCompanyData] = useState({});
  const [note, setNote] = useState("");
  const { currentUser } = useContext(AuthContext);

  const fetchLocationData = (locationId) => {
    const locationRef = ref(database, `locations/${locationId}/guidelines`);
    onValue(locationRef, (snapshot) => {
      if (snapshot.exists()) {
        setLocationData((prev) => ({
          ...prev,
          [locationId]: snapshot.val(),
        }));
      }
    });

    // Fetch notes
    const notesRef = ref(database, `locations/${locationId}/notes`);
    onValue(notesRef, (snapshot) => {
      let notesList = [];
      snapshot.forEach((childSnapshot) => {
        notesList.push({ id: childSnapshot.key, ...childSnapshot.val() });
      });
      // Store the latest 3 notes
      setLocationData((prev) => ({
        ...prev,
        [locationId]: {
          ...prev[locationId],
          latestNotes: notesList.slice(-3).reverse(),
        },
      }));
    });
  };

  useEffect(() => {
    data.forEach((watch) => {
      fetchLocationData(watch.locationId);
    });
  }, [data]);

  const fetchCompanyData = (companyId) => {
    const companyRef = ref(database, `Companies/${companyId}`);
    onValue(companyRef, (snapshot) => {
      if (snapshot.exists()) {
        const companyInfo = snapshot.val();
        setCompanyData((prev) => ({
          ...prev,
          [companyId]: {
            ...companyInfo,
            companyImage: companyInfo.companyImage || null,
          },
        }));
      }
    });
  };

  useEffect(() => {
    const fetchWatches = async () => {
      try {
        const watchRef = ref(database, "Watches");
        const q = query(
          watchRef,
          orderByChild("companyId"),
          equalTo(companyId)
        );
        onValue(q, (snapshot) => {
          let list = [];
          snapshot.forEach((childSnapshot) => {
            const watch = { id: childSnapshot.key, ...childSnapshot.val() };
            list.push(watch);
          });
          setData(list); // Update state with fetched data
        });
      } catch (error) {
        console.error("Error fetching watches:", error);
      }
    };

    if (companyId) {
      fetchWatches();
    }
  }, [companyId]);

  const handleSendNote = async (watch) => {
    if (!note.trim()) {
      alert("Please enter a note.");
      return;
    }

    try {
      const noteRef = ref(database, `locations/${watch.locationId}/notes`);

      console.log(watch.locationId, "this is locationdi");

      await push(noteRef, {
        note,
        timestamp: new Date().toISOString(),
        createdBy: currentUser.email,
      });

      setNote(""); // Clear the note input after sending
      alert("Note added successfully!");
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  const getIcon = (alarmCode) => {
    const iconUrl =
      {
        C: cancelledIconUrl,
        G: greenIconUrl,
        O: orangeWatchIconUrl,
        R: redWatchIconUrl,
      }[alarmCode] || mapIcon; // Default to mapIcon if alarmCode doesn't match

    return L.icon({
      iconUrl,
      iconSize: [45, 45], // Adjust icon size if necessary
      iconAnchor: [22.5, 45], // Adjust anchor if necessary
      popupAnchor: [0, -45], // Adjust popup anchor if necessary
    });
  };

  // Calculate the average center for the map (or a good center if no data)
  const calculateMapCenter = () => {
    if (data.length === 0) return [0, 0]; // Default center
    const latitudes = data.map((watch) => watch.watchLatitude);
    const longitudes = data.map((watch) => watch.watchLongitude);
    const avgLat = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
    const avgLng = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;
    return [avgLat, avgLng];
  };

  return (
    <div className="chart">
      <h3
        style={{
          fontWeight: "400",
        }}
      >
        Watch Locations
      </h3>
      {data.length === 0 ? (
        <p>No watches to display</p> // Display message when no watches are available
      ) : (
        <MapContainer
          center={calculateMapCenter()} // Dynamically set the map center based on data
          zoom={12} // Default zoom level, will be adjusted to fit all watches
          style={{ height: "500px", width: "100%" }} // Adjust map size
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <UpdateMapBounds watches={data} /> {/* Auto-fit map to watches */}
          {data.map((watch) => (
            <Marker
              key={watch.id}
              position={[watch.watchLatitude, watch.watchLongitude]} // Use latitude and longitude from the watch data
              icon={getIcon(watch.alarmCode)}
            >
              <Popup>
                <div>
                  <b>{watch.displayName}</b> <br />
                  <img
                    src={companyData[companyId]?.companyImage || null}
                    alt="Company"
                    style={{
                      width: "100px",
                      height: "auto",
                      marginBottom: "10px",
                    }}
                  />
                  <br />
                  <strong>Company:</strong> {watch.companyName} <br />
                  <strong>Location:</strong> {watch.locationName} <br />
                  <strong>Latitude:</strong> {watch.watchLatitude} <br />
                  <strong>Longitude:</strong> {watch.watchLongitude} <br />
                  <br />
                  <strong>Location Guidelines:</strong>
                  <p>
                    {locationData[watch.locationId]?.guidelines ||
                      "No guidelines available"}
                  </p>
                  <br />
                  <div>
                    <strong>Send a Note:</strong>
                    <textarea
                      value={note}
                      onChange={(e) => setNote(e.target.value)}
                      placeholder="Enter your note"
                      rows="3"
                      style={{ width: "100%" }}
                    />
                    <button
                      onClick={() => handleSendNote(watch)}
                      style={{ marginTop: "10px", background: "black",
                        cursor: "pointer",
                        color: "white",  }}
                    >
                      Send Note
                    </button>
                    <br />
                    <br />
                  </div>
                  <div
                    style={{
                      maxHeight: "100px",
                      overflowY: "auto",
                    }}
                  >
                    <strong>Recent Notes:</strong>
                    {locationData[watch.locationId]?.latestNotes?.length ? (
                      locationData[watch.locationId]?.latestNotes.map(
                        (note) => (
                          <div
                            key={note.id}
                            style={{
                              padding: "1px",
                              border: "0.5px solid black",
                              borderRight: "none",
                              borderLeft: "none",
                              borderBottom: "none",
                            }}
                          >
                            <p>{note.note}</p>
                            <small>
                              By: {note.createdBy} at{" "}
                              {new Date(note.timestamp).toLocaleString()}
                            </small>
                          </div>
                        )
                      )
                    ) : (
                      <p>No notes available</p>
                    )}
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
        </MapContainer>
      )}
    </div>
  );
};

export default CompanyWatchesMap;
