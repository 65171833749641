import "./new.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useState, useEffect, useContext } from "react";
import {
  getDatabase,
  ref,
  set,
  push,
  serverTimestamp,
  onValue,
} from "firebase/database";
import { database } from "../../firebase";
import { AuthContext } from "../../context/AuthContext"; // Assuming you have an AuthContext
import { Link } from "react-router-dom";
import useCountryList from "react-select-country-list"; // Import country list library

const NewLocation = ({ inputs, title }) => {
  const [data, setData] = useState({});
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({
    companyId: "",
    companyName: "",
  });
  const [user, setUser] = useState(null); // Store the fetched user data
  const { currentUser } = useContext(AuthContext);
  const [locationCodeError, setLocationCodeError] = useState(""); // Error state for locationCode uniqueness
  const [existingLocationCodes, setExistingLocationCodes] = useState([]);
  const countryOptions = useCountryList().getData();

  // Fetch user details from the "users" node based on currentUser.uid
  useEffect(() => {
    const userRef = ref(database, `users/${currentUser.uid}`);
    onValue(userRef, (snapshot) => {
      const userData = snapshot.val();
      console.log(userData);
      setUser(userData);

      // If the userRole is 2, automatically set the company info and lock fields
      if (userData.userRole === 2) {
        setSelectedCompany({
          companyId: userData.companyId,
          companyName: userData.companyName,
        });
        setData({
          companyId: userData.companyId,
          companyName: userData.companyName,
        });
      }
    });
  }, [currentUser.uid]);

  // Fetch companies if userRole is 1
  useEffect(() => {
    if (user && user.role === 1) {
      const companiesRef = ref(database, "Companies");
      onValue(companiesRef, (snapshot) => {
        const companiesData = snapshot.val();
        const companyList = companiesData
          ? Object.keys(companiesData).map((key) => ({
              companyId: key,
              companyName: companiesData[key].companyName, // Use companyName instead of name
            }))
          : [];
        setCompanies(companyList);
      });
    }
  }, [user]);

  useEffect(() => {
    const locationCodeRef = ref(database, "locations");
    onValue(locationCodeRef, (snapshot) => {
      const codes = [];
      snapshot.forEach((childSnapshot) => {
        codes.push(childSnapshot.val().locationCode);
      });
      setExistingLocationCodes(codes);
    });
  }, []);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  // Handle company dropdown change (for userRole 1)
  const handleCompanyChange = (e) => {
    const selectedId = e.target.value;
    const selectedCompanyName = companies.find(
      (company) => company.companyId === selectedId
    )?.companyName;
    setSelectedCompany({
      companyId: selectedId,
      companyName: selectedCompanyName,
    });
    setData({
      ...data,
      companyId: selectedId,
      companyName: selectedCompanyName,
    });

    console.log(selectedCompanyName);
  };

  const handleAdd = async (e) => {
    e.preventDefault();

    // Check if the locationCode already exists
    if (existingLocationCodes.includes(data.locationCode)) {
      setLocationCodeError(
        "Location code already exists. Please choose another."
      );
    } else {
      // Proceed with adding the location if locationCode is unique
      addNewLocation();
    }
  };

  const generateUniqueLocationCode = () => {
    let uniqueCode;
    do {
      // Generate a 5-digit number (from 00000 to 99999) and concatenate "L" in front
      uniqueCode = `L${String(Math.floor(Math.random() * 100000)).padStart(
        5,
        "0"
      )}`;
    } while (existingLocationCodes.includes(uniqueCode));

    // The uniqueCode should now have a total length of 6 (1 for 'L' + 5 digits)
    return uniqueCode;
  };

  const addNewLocation = async () => {
    try {
      const locationCode = generateUniqueLocationCode(); // Generate the unique location code

      // Create a new reference with the unique location code as the ID
      const newLocationRef = ref(database, `locations/${locationCode}`);

      // Set the data to the new reference location
      await set(newLocationRef, {
        ...data,
        // locationCode, // Ensure the location code is set
        companyId: selectedCompany.companyId || user.companyId,
        companyName: selectedCompany.companyName || user.companyName,
        timeStamp: serverTimestamp(),
        guidelines: data.guidelines || [], // Add empty array if undefined
        notes: data.notes || [], // Add empty array if undefined
      });

      // Update the company's ar.location with the new location
      const companyRef = ref(
        database,
        `Companies/${selectedCompany.companyId}/ar/location/${locationCode}`
      );
      await set(companyRef, {
        account_number: "", // Leave empty for future editing
        en: false, // Set default values as needed
        users: "", // Leave empty for future editing
      });
      alert(`Location added successfully with code:, ${locationCode}`);
      console.log("Location added successfully with code:", locationCode);
    } catch (error) {
      console.log(error);
    }
  };

  if (currentUser.role !== 1 && currentUser.role !== 2) {
    return (
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <p>You do not have access to this page, please contact your admin.</p>
        <br />
        <Link
          to="/"
          style={{
            textDecoration: "underline",
            color: "black",
            fontWeight: "bold",
          }}
        >
          Go Back
        </Link>
      </div>
    );
  }

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}</h1>
        </div>
        <div className="bottom">
          <div className="right">
            <form onSubmit={handleAdd}>
              {/* Conditionally render company dropdown if userRole is 1 */}
              {user && user.role === 1 ? (
                <div className="formInput">
                  <label>Company *</label>
                  <select onChange={handleCompanyChange} required
                      style={{
                        height: "30px",
                        width: "250px",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                      }}
                  >
                    <option value="">Select Company</option>
                    {companies.map((company) => (
                      <option key={company.companyId} value={company.companyId}>
                        {company.companyName}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  <input
                    id={input.id}
                    type={input.type}
                    maxLength={input.maxLength}
                    placeholder={input.placeholder}
                    value={data[input.id] || ""}
                    onChange={handleInput}
                    required={input.required}
                    disabled={
                      user &&
                      user.role === 2 &&
                      (input.id === "companyName" || input.id === "companyId")
                    } // Disable if userRole is 2 and for company fields
                  />
                </div>
              ))}
              {/* Country Dropdown */}
              <div className="formInput">
                <label htmlFor="country">Country *</label>
                <select
                  id="country"
                  onChange={handleInput} // Use handleInput for changes
                  required
                  style={{
                    height: "30px",
                    width: "100%",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                  }}
                >
                  <option value="">Select a Country</option>
                  {countryOptions.map((country) => (
                    <option key={country.value} value={country.label}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>
              {/* Location Code Input Field */}
              <div className="formInput">
                <label>Location Code *</label>
                <input
                  id="locationCode"
                  type="text"
                  placeholder="Enter unique location code"
                  value={data.locationCode || ""}
                  onChange={handleInput}
                  required
                  maxLength={14}
                />
                {locationCodeError && (
                  <p className="error">{locationCodeError}</p>
                )}
              </div>
              <span
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                  color: "gray",
                  fontSize: "10px",
                }}
              >
                Please Fill all the required fields (*)
              </span>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  marginLeft: "50px",
                }}
              >
                <button type="submit">Add Location</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewLocation;
